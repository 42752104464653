<template>
  <!-- 个人成绩详情 -->
  <div class="personalDetails">
    <div v-if="isAction === 1">
      <div class="detaBox">
        <div class="rowBox">
          <div class="rightBox">选手姓名：</div>
          <div class="leftBox">{{ playerData.name }}</div>
        </div>
        <div class="rowBox">
          <div class="rightBox">比赛排名：</div>
          <div class="leftBox">{{ playerData2.ranking }}</div>
        </div>
        <div class="rowBox">
          <div class="rightBox">比赛项目：</div>
          <div class="leftBox">{{ playerData1.name }}</div>
        </div>
        <div class="rowBox">
          <div class="rightBox">比赛类型：</div>
          <div class="leftBox">{{ playerData1.type }}</div>
        </div>
        <div class="rowBox">
          <div class="rightBox">单位：</div>
          <div class="leftBox">{{ playerData.company }}</div>
        </div>
        <div class="rowBox">
          <div class="rightBox">总环数：</div>
          <div class="leftBox">{{ playerData2.shootScore }}</div>
        </div>
      </div>
      <div class="tableContent">
        <div class="searchBox">
          <el-button
            class="seaBtn"
            type="primary"
            size="small"
            @click="certificateBtn"
            >打印证书</el-button
          >
          <el-button type="primary" size="small" @click="achievementBtn"
            >打印成绩</el-button
          >
        </div>
        <el-table
          :data="playerScoreList"
          max-height="200"
          border
          style="width: 100%"
          size="small"
        >
          <el-table-column prop="shootCount" label="发序"> </el-table-column>
          <el-table-column prop="ringValue" label="环值"> </el-table-column>
          <el-table-column label="方向">
            <template slot-scope="scope">
              <i v-if="scope.row.direction === 1" class="el-icon-top"></i>
              <i v-if="scope.row.direction === 2" class="el-icon-bottom"></i>
              <i v-if="scope.row.direction === 3" class="el-icon-back"></i>
              <i v-if="scope.row.direction === 4" class="el-icon-right"></i>
              <i v-if="scope.row.direction === 5" class="el-icon-top-left"></i>
              <i v-if="scope.row.direction === 6" class="el-icon-top-right"></i>
              <i
                v-if="scope.row.direction === 7"
                class="el-icon-bottom-right"
              ></i>
              <i
                v-if="scope.row.direction === 8"
                class="el-icon-bottom-left"
              ></i>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="danwei" label="总环数"> </el-table-column> -->
          <el-table-column prop="shootTime" label="时间（秒）">
          </el-table-column>
          <!-- <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="editPersonal(scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column> -->
        </el-table>
      </div>
    </div>
    <Achievement
      v-else-if="isAction === 2"
      @isActionClick="isActionClick"
    ></Achievement>
    <Certificate
      v-else-if="isAction === 3"
      @isActionClick="isActionClick"
    ></Certificate>
    <!-- <EditPersonal
      ref="edit"
      @getSelectScoreByPlayerId="getSelectScoreByPlayerId"
    ></EditPersonal> -->
  </div>
</template>

<script>
import { getSelectScoreByPlayerId } from '@/api/scoreQuery/index.js';
import Achievement from './achievement.vue'
import Certificate from './certificate.vue'
// import EditPersonal from './editPersonal.vue';
export default {
  name: 'PersonalDetails',
  components: {
    Achievement,
    Certificate,
    // EditPersonal
  },
  props: {},
  data() {
    return {
      scoreByPlayer: {
        playerId: parseInt(sessionStorage.getItem('playerId')),
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      playerData: {}, // 选手信息
      playerData1: {}, // 选手信息
      playerData2: {}, // 选手信息
      playerScoreList: [], // 选手成绩信息
      isAction: 1,
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectScoreByPlayerId();
  },
  methods: {
    // 根据选手id查询成绩详情
    async getSelectScoreByPlayerId() {
      const res = await getSelectScoreByPlayerId(this.scoreByPlayer);
      // console.log(res);
      if (res.status === 200) {
        this.playerData = res.data.playerManagement;
        this.playerData1 = res.data.competitionManagement;
        this.playerData2 = res.data;
        this.playerScoreList = res.data.shootingScoreList;
      }
    },
    // 编辑
    // editPersonal(val) {
    //   console.log(val);
    //   this.$refs.edit.openEdit(val);
    // },

    // ------------------------------------------------------
    // 打印证书按钮
    certificateBtn() {
      this.isAction = 3;
    },
    // 打印成绩按钮
    achievementBtn() {
      this.isAction = 2;
    },
    isActionClick() {
      this.isAction = 1;
    },
  }
}
</script>

<style lang="scss" scoped>
.personalDetails {
  padding: 20px;
  .detaBox {
    .rowBox {
      display: flex;
      margin-bottom: 30px;
      .rightBox {
        width: 90px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
        text-align: right;
      }
      .leftBox {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 20px;
      }
    }
  }
  .tableContent {
    margin-top: 40px;
    .searchBox {
      margin-bottom: 15px;

      .el-input {
        width: 180px;
        margin-right: 15px;
        // margin-bottom: 20px;
      }
      .el-button {
        background: #06206b;
      }
      .seaBtn {
        margin-right: 20px;
      }
    }

    .el-table--border {
      // border-bottom: 1px solid #ebeef5;
      border-right: 1px solid #ebeef5;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
// 兼容火狐
::v-deep .el-table__body-wrapper {
  overflow-y: scroll;
  scrollbar-color: #d7d7d7 transparent;
  scrollbar-width: thin;
}
// 兼容谷歌
// 滚动条的宽度
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
// 滚动条的滑块
::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 3px;
}
::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
  background: #fff !important;
}
</style>
