<template>
  <div class="addPlayer">
    <div class="topBox">
      <div class="proBox">新增选手</div>
    </div>
    <div>
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <!-- <el-form-item label="比赛名称" prop="competitionId">
          <el-select
            v-model="formData.competitionId"
            placeholder="请选择比赛名称"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in CompetitionList"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="选手姓名" prop="name">
          <el-input
            v-model.trim="formData.name"
            placeholder="请输入选手姓名"
            clearable
            :style="{ width: '30%' }"
            maxlength="10"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select
            v-model="formData.sex"
            placeholder="请选择性别"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in field104Options"
              :key="index"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <!-- <el-input
            v-model="formData.age"
            placeholder="请输入年龄"
            clearable
            :style="{ width: '30%' }"
          >
          </el-input> -->
          <el-input-number
            v-model="formData.age"
            :style="{ width: '10%' }"
            :precision="0"
            :max="80"
            :min="6"
          >
          </el-input-number>
        </el-form-item>
        <el-form-item label="身份证号" prop="idCard">
          <el-input
            v-model.trim="formData.idCard"
            placeholder="请输入身份证号"
            clearable
            :style="{ width: '30%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="telephone">
          <el-input
            v-model="formData.telephone"
            placeholder="请输入联系方式"
            clearable
            :style="{ width: '30%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="城市" prop="city">
          <el-select
            v-model="formData.city"
            placeholder="请选择场地城市"
            clearable
            filterable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in cityOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位" prop="company">
          <el-input
            v-model.trim="formData.company"
            placeholder="请输入单位"
            clearable
            :style="{ width: '30%' }"
            maxlength="45"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="全国编码">
          <el-input
            v-model.trim="formData.nationalCode"
            placeholder="请输入全国编码"
            clearable
            :style="{ width: '30%' }"
            maxlength="255"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getSelectCompetitionDrop, addPlayer } from '@/api/playerManagement/index.js'
export default {
  name: 'AddPlayer',
  inject: ['reload'],
  components: {},
  props: {},
  data() {
    return {
      CompetitionList: [], // 比赛列表
      formData: {
        competitionId: null,
        name: '',
        sex: null,
        age: null,
        idCard: '',
        nationalCode: "",
        telephone: null,
        company: '',
        city:''
      },
      cityOptions: [],
      loading: false,
      rules: {
        competitionId: [{
          required: true,
          message: '请选择比赛名称',
          trigger: 'change'
        }],
        city: [{
          required: true,
          message: '请选择城市',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入选手姓名',
          trigger: 'blur'
        }],
        sex: [{
          required: true,
          message: '请选择性别',
          trigger: 'change'
        }],
        age: [{
          required: true,
          message: '请输入年龄',
          trigger: 'blur'
        }],
        idCard: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: '身份证号码有误，请核对！',
          trigger: 'blur'
        }],
        telephone: [{
          required: true,
          message: '请输入联系方式',
          trigger: 'blur'
        }, {
          pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
          message: '手机号有误，请核对！',
          trigger: 'blur'
        }],
        company: [{
          required: true,
          message: '请输入单位',
          trigger: 'blur'
        }],
      },
      field104Options: [{
        "label": "男",
        "value": true
      }, {
        "label": "女",
        "value": false
      }]
    }
  },
  computed: {},
  watch: {},
  created() {
    // this.getSelectCompetitionDrop();
    this.getAllProvinceAndCityList();
  },
  methods: {
    getAllProvinceAndCityList() {     // 获取全国所有省份和城市的列表
      var that = this;
      this.$http.get('https://restapi.amap.com/v3/config/district', {   // 高德地图的API
        params: {
          key: '09c94293822de401f62911d40c755415',   // 这个是申请的 Key，读者自行写入
          keywords: '中国',
          subdistrict: 2,
          extensions: 'base'
        }
      }).then((response) => {
        // console.log(response);
        that.cityOptions = [];
        for (let i = 0; i < response.data.districts[0].districts.length; i++) {
          for (let j = 0; j < response.data.districts[0].districts[i].districts.length; j++) {
            that.cityOptions.push(response.data.districts[0].districts[i].districts[j]);
          }
        }
        // console.log('城市', that.cityOptions);
      });
    },
    // 获取比赛列表
    async getSelectCompetitionDrop() {
      const res = await getSelectCompetitionDrop();
      // console.log(res);
      if (res.status === 200) {
        this.CompetitionList = res.data;
      }
    },
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        // TODO 提交表单
        this.formData.age = parseInt(this.formData.age);
        // console.log(this.formData);
        // console.log(this.val);
        this.loading = true;
        const res = await addPlayer(this.formData)
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '新增成功!'
          });
          this.loading = false;
          this.$router.push('/home/playerInformation');
          this.reload(); // 需要刷新页面
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '新增失败!' + res.msg
          });
          this.loading = false;
        }
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
      this.$router.push('/home/playerInformation');
    },
  }
}
</script>

<style lang="scss" scoped>
.addPlayer {
  .topBox {
    width: calc(100% - 50px);
    height: 60px;
    margin-bottom: 40px;
    margin-left: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    .proBox {
      cursor: pointer;
      width: 70px;
      height: 60px;
      line-height: 80px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      box-sizing: border-box;
      border-bottom: 1px solid #06206b;
    }
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-form-item__content {
      margin-left: 14% !important;
      .resBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #eaefff;
        border-color: #eaefff;
        border-radius: 2px;
        span {
          color: #06206b;
        }
      }
      .subBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #06206b;
        border-color: #06206b;
        border-radius: 2px;
        margin-left: 50px;
      }
    }
  }
}
</style>
