<template>
  <div class="edit">
    <el-dialog
      title="编辑用户"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item prop="name">
          <span>用户名</span><br />
          <el-input
            placeholder="请输入用户名"
            v-model.trim="ruleForm.name"
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <span>角色</span>
          <br />
          <el-select
            v-model="roleVal"
            placeholder="请选择角色"
            clearable
            value-key="roleId"
            @change="roleChange"
          >
            <el-option
              v-for="item in rolesList"
              :label="item.roleName"
              :value="item"
              :key="item.roleId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectRole } from '@/api/systemManagement/roleManagement/index.js'
import { update } from '@/api/systemManagement/userManagement/index.js'
export default {
  data() {
    return {
      dialogVisible: false,
      rolesList: [], // 角色列表
      ruleForm: {
        id: null,
        name: '',
        roleList: []
      }, // 用户信息
      roleVal: {},

      // --------------------------------
      userId: null,
      OrganizationsList: [],
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { max: 10, message: '长度在 10 个字符以内', trigger: 'change' }
        ],
        roleId: [
          { required: true, message: '请选择角色', trigger: 'change' }
        ],
      }
    };
  },
  methods: {
    // 打开当前模态框
    openEdit(val) {
      // console.log(val);
      this.ruleForm.id = val.id;
      this.ruleForm.name = val.name;
      this.ruleForm.roleList = val.roleList;
      if (val.roleList.length > 0) {
        this.roleVal = val.roleList[0];
      } else {
        this.roleVal = {};
      }
      this.dialogVisible = true;
      this.getSelectRole();
    },
    // 获取角色列表
    async getSelectRole() {
      const res = await getSelectRole();
      // console.log(res);
      if (res.status === 200) {
        this.rolesList = res.data;
      }
    },
    // 选角色
    roleChange(val) {
      console.log('111', typeof (val));
      this.ruleForm.roleList = [];
      this.ruleForm.roleList.push(val);
      if (typeof (val) === 'string') {
        console.log('queren');
        this.ruleForm.roleList = [];
      }
    },
    // 点击提交
    submitForm(formName) {
      if (this.ruleForm.roleList.length === 0) {
        this.$message({
          type: 'warning',
          message: '请选择角色',
          showClose: true
        })
        return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.ruleForm);
          const res = await update(this.ruleForm);
          console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '修改成功！',
              type: 'success'
            });
            this.dialogVisible = false;
            this.$emit('getSelect');
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.edit {
  ::v-deep.el-dialog {
    height: 300px;
    overflow-y: auto;
    // position: relative;
  }

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type="number"] {
    -moz-appearance: textfield !important;
  }
  .el-button--primary {
    margin-left: 30px;
  }

  // height: 80%;
  .el-input,
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
</style>