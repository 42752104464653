<template>
  <!-- 打印证书 -->
  <div class="certificate">
    <div id="printRoot">
      <div class="printMeBox">
        <div class="titleBox">荣誉证书</div>
        <div class="starsBox"></div>
        <div class="nameBox">{{ scoreData.name }}</div>
        <div class="lineBox"></div>
        <div class="contBox">
          <span class="nameBox1">{{ playerData.name }}</span>
          <span>选手在</span>
          <span>{{ scoreData.name }}</span>
          <span>中荣获</span>
          <span class="rankBox">第{{ ranking }}名</span>
          <span>，特颁此证。</span>
        </div>
        <div class="sealBox">
          <div class="conBox">{{ gameTime }}</div>
        </div>
        <div class="bottomBox">
          <span class="titBox"
            >{{ scoreData.sponsor }}&{{ scoreData.sponsors }}</span
          >
        </div>
      </div>
    </div>
    <div class="btnBox">
      <button class="previewBox" @click="closeBtn">返回</button>
      <button class="printBox" v-print="{ id: 'printRoot' }">打印</button>
    </div>
  </div>
</template>

<script>
import { getSelectScoreByPlayerId } from '@/api/scoreQuery/index.js';
export default {
  name: 'Certificate',
  components: {},
  props: {
  },
  data() {
    return {
      scoreByPlayer: {
        playerId: parseInt(sessionStorage.getItem('playerId')),
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      playerData: {}, // 选手信息
      scoreData: {}, // 比赛详情
      ranking: '',
      gameTime: ''
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectScoreByPlayerId();
    this.getDate();
  },
  methods: {
    // 根据选手id查询成绩详情
    async getSelectScoreByPlayerId() {
      const res = await getSelectScoreByPlayerId(this.scoreByPlayer);
      // console.log(res);
      if (res.status === 200) {
        if (res.data.ranking) {
          this.ranking = this.changeNumToHan(res.data.ranking);
        }
        this.playerData = res.data.playerManagement;
        this.scoreData = res.data.competitionManagement;
      }
    },
    closeBtn() {
      this.$emit('isActionClick');
    },
    // 数字转汉字
    changeNumToHan(num) {
      let arr1 = new Array('零', '一', '二', '三', '四', '五', '六', '七', '八', '九');
      let arr2 = new Array('', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万', '十', '百', '千', '亿');// 可继续追加更高位转换值
      if (!num || isNaN(num)) {
        return "零";
      }
      let english = num.toString().split("");
      let result = "";
      for (let i = 0; i < english.length; i++) {
        let des_i = english.length - 1 - i; // 倒序排列设值
        result = arr2[i] + result;
        let arr1_index = english[des_i];
        result = arr1[arr1_index] + result;
      }
      //将【零千、零百】换成【零】 【十零】换成【十】
      result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十');
      //合并中间多个零为一个零
      result = result.replace(/零+/g, '零');
      //将【零亿】换成【亿】【零万】换成【万】
      result = result.replace(/零亿/g, '亿').replace(/零万/g, '万');
      //将【亿万】换成【亿】
      result = result.replace(/亿万/g, '亿');
      //移除末尾的零
      result = result.replace(/零+$/, '');
      //将【零一十】换成【零十】
      //result = result.replace(/零一十/g, '零十');//貌似正规读法是零一十
      //将【一十】换成【十】
      result = result.replace(/^一十/g, '十');
      return result;
    },
    // 获取当前日期
    getDate() {
      let date = new Date();
      this.gameTime = this.ConverToDate(date);
      // console.log(this.gameTime);
    },
    // 日期转大写
    ConverToDate(date) {
      let chinese = ['0', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
      let y = date.getFullYear().toString();
      let m = (date.getMonth() + 1).toString();
      let result = "";
      for (let i = 0; i < y.length; i++) {
        result += chinese[y.charAt(i)];
      }
      result += "年";
      if (m.length == 2) {
        if (m.charAt(0) == "1") {
          result += ("十" + chinese[m.charAt(1)] + "月");
        }
      } else {
        result += (chinese[m.charAt(0)] + "月");
      }
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
.certificate {
  .printMeBox {
    width: 595px;
    height: 842px;
    background-image: url("~@/assets/zhengshu.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 124px;
    .titleBox {
      width: 144px;
      height: 50px;
      font-size: 36px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #06206b;
      line-height: 50px;
      text-align: center;
      margin-left: 225px;
    }
    .starsBox {
      width: 177px;
      height: 33px;
      background-image: url("~@/assets/xingxing.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 209px;
      margin-top: 7px;
    }
    .nameBox {
      width: 383px;
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      line-height: 30px;
      text-align: center;
      margin-left: 106px;
      margin-top: 75px;
    }
    .lineBox {
      width: 378px;
      height: 0;
      border-top: 5px dashed #d7d7d7;
      margin-left: 109px;
      margin-top: 7px;
    }
    .contBox {
      width: 419px;
      height: 70px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      line-height: 35px;
      margin-left: 88px;
      margin-top: 85px;
      .nameBox1 {
        border-bottom: 1px solid #333;
        margin-left: 40px;
        margin-right: 2px;
      }
      .rankBox {
        color: #e9cc2d;
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 700;
      }
    }
    .sealBox {
      width: 116px;
      height: 116px;
      background-image: url("~@/assets/seal.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      margin-left: 379px;
      margin-top: 5px;
      // border: 1px dashed #d7d7d7;
      .conBox {
        width: 150px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #606060;
        line-height: 116px;
      }
    }
    .bottomBox {
      position: relative;
      width: 386px;
      height: 1px;
      background: #979797;
      margin-top: 158px;
      margin-left: 100px;
      .titBox {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        background-image: url("~@/assets/zhengshubj.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: inline-block;
        min-width: 160px;
        height: 22px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 22px;
        padding: 0 5px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .btnBox {
    width: 100%;
    height: 30px;
    margin-top: 27px;
    margin-bottom: 100px;
    .previewBox {
      cursor: pointer;
      width: 87px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #06206b;
      background: #eaefff;
      border: 0;
      border-radius: 2px;
      margin-left: 234px;
      margin-right: 33px;
    }
    .printBox {
      cursor: pointer;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #eaefff;
      background: #06206b;
      border: 0;
      border-radius: 2px;
    }
  }
}
@media print {
  body {
    // -webkit-print-color-adjust: exact;
    // -moz-print-color-adjust: exact;
    // -ms-print-color-adjust: exact;
    // print-color-adjust: exact;
    margin: 0 auto;
    #printRoot {
      width: 100%;
      height: 842px * 16.1;
      background-color: #fff;
      padding-top: 350px;
    }
    .printMeBox {
      width: 595px * 15;
      height: 842px * 15;
      background-image: url("~@/assets/zhengshu.jpg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding-top: 124px * 15;
      .titleBox {
        width: 144px * 15;
        height: 50px * 15;
        font-size: 36px * 15;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #06206b;
        line-height: 50px * 15;
        text-align: center;
        margin-left: 225px * 15;
      }
      .starsBox {
        width: 177px * 15;
        height: 33px * 15;
        background-image: url("~@/assets/xingxing.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 209px * 15;
        margin-top: 7px * 15;
      }
      .nameBox {
        width: 383px * 15;
        height: 30px * 15;
        font-size: 22px * 15;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 30px * 15;
        text-align: center;
        margin-left: 106px * 15;
        margin-top: 75px * 15;
      }
      .lineBox {
        width: 378px * 15;
        height: 0;
        border-top: 5px * 15 dashed #d7d7d7;
        margin-left: 109px * 15;
        margin-top: 7px * 15;
      }
      .contBox {
        width: 419px * 15;
        height: 70px * 15;
        font-size: 18px * 15;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
        line-height: 35px * 15;
        margin-left: 88px * 15;
        margin-top: 85px * 15;
        .nameBox1 {
          border-bottom: 1px * 15 solid #333;
          margin-left: 40px * 15;
          margin-right: 2px * 15;
        }
        .rankBox {
          color: #e9cc2d;
          font-size: 24px * 15;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 700;
        }
      }
      .sealBox {
        width: 116px * 15;
        height: 116px * 15;
        background-image: url("~@/assets/seal.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        margin-left: 379px * 15;
        margin-top: 5px * 15;
        // border: 1px dashed #d7d7d7;
        .conBox {
          width: 150px * 15;
          font-size: 16px * 15;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #606060;
          line-height: 116px * 15;
        }
      }
      .bottomBox {
        position: relative;
        width: 386px * 15;
        height: 15px;
        background: #979797;
        margin-top: 152px * 15;
        margin-left: 100px * 15;
        .titBox {
          position: absolute;
          top: -10px * 15;
          left: 50%;
          transform: translate(-50%, 0);
          background-image: url("~@/assets/zhengshubj.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          display: inline-block;
          min-width: 160px * 15;
          height: 22px * 15;
          font-size: 12px * 15;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303030;
          line-height: 22px * 15;
          padding: 0 5px * 15;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
@page {
  /* 打印A4大小 */
  size: auto A4 landscape;
  margin: 0 auto;
}
</style>
