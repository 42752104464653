import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
/* 引入资源请求插件 */
import VueResource from 'vue-resource'


// 导入flexible
// import 'amfe-flexible'
// import 'px2rem'
import './utils/flexible'
// import './utils/px2rem'
//在main.js引入
import print from "./assets/vue-print-nb";
import md5 from 'js-md5';
Vue.prototype.$md5 = md5;
Vue.use(print);
// import Print from '@/plugins/print.js'
// Vue.use(Print)
// import $ from 'jquery'; // 全局引入jQuery
// import "@/plugins/jQuery.print.js"; // 顺序必须在jquery下

Vue.use(ElementUI)
/* 使用VueResource插件 */
Vue.use(VueResource)
const Base64 = require('js-base64').Base64

Vue.config.productionTip = false

new Vue({
  Base64,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.addEventListener('popstate', function (e) {
  router.isBack = true;
}, false);
window.addEventListener('storage', function (e) {
  sessionStorage.setItem(e.key, e.oldValue);
});
window.addEventListener('storage', function () {
  sessionStorage.clear();
  window.location.replace('/');
});
