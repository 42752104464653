<template>
  <div class="editRole">
    <!-- 编辑角色 -->
    <el-dialog
      title="编辑角色"
      :visible.sync="drawer"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <div class="demo-drawer__content">
        <el-form
          :model="ruleForm"
          ref="ruleForm"
          class="demo-ruleForm"
          size="small"
        >
          <el-form-item prop="name">
            <span>角色名称</span>
            <br />
            <el-input
              v-model.trim="ruleForm.roleName"
              maxlength="10"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="setting">
          <div class="jurisdiction">
            <div class="title">
              <span>权限设置</span>
              <el-button
                type="danger"
                plain
                size="mini"
                @click="editJurisdiction"
                >编辑</el-button
              >
            </div>
            <div class="name"></div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button
          type="primary"
          size="small"
          @click="submitForm('ruleForm')"
          :loading="loading"
        >
          确定</el-button
        >
      </div>
    </el-dialog>
    <JurisdictionVue
      ref="jurisdiction"
      @changeEdit="changeEdit"
    ></JurisdictionVue>
  </div>
</template>

<script>
import JurisdictionVue from './Jurisdiction.vue'
import { updateRole, getIdMenu } from '@/api/systemManagement/roleManagement/index.js'
export default {
  name: 'EditRole',
  components: {
    JurisdictionVue
  },
  data() {
    return {
      drawer: false,
      loading: false,
      ruleForm: {
        id: 0,
        menuIds: [],//当前用户已有的菜单id数组
        roleName: "",
        updateAuth: false
      },
      changMenu: [],//可能改变的菜单id数组
      into: false,//是否进入权限修改页面
    };
  },
  methods: {
    // 获取当前角色已有的菜单
    async getIdNowMenu() {
      console.log('alreadymenu');
      const res = await getIdMenu({ id: this.ruleForm.id })
      console.log('alreadymenu1');
      let data = []
      if (res.status === 200) {
        console.log('alreadymenu2');

        res.data.authList.forEach((item) => {
          console.log('alreadymenu3');
          data.push(item.id)
          // this.menuList.push(item.id)
        })
      }
      this.ruleForm.menuIds = data
    },
    // 进入权限编辑传递数组和状态
    changeEdit(val) {
      console.log('以进入权限修改页面', val);
      this.into = true;
      this.ruleForm.updateAuth = true;
      this.ruleForm.menuIds = val
    },
    // 打开编辑模态框
    openEdit(val) {
      // console.log(val)
      this.ruleForm.roleName = val.roleName;
      this.ruleForm.id = val.roleId;
      this.getIdNowMenu()
      this.drawer = true;
    },
    // 点击确认
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        // console.log(valid);
        if (valid) {
          if (this.into) {
            this.loading = true;
            const res = await updateRole(this.ruleForm);
            // console.log(res);
            if (res.status === 200) {
              this.$message({
                showClose: true,
                message: '编辑成功',
                type: 'success'
              });
              this.loading = false;
              this.drawer = false;
              this.$emit('getSelectRole');
            } else {
              this.$message({
                showClose: true,
                message: res.msg,
                type: 'error'
              });
              this.loading = false;
            }
          } else {

          }
          // this.loading = true;
          // const res = await updateRole(this.ruleForm);
          // // console.log(res);
          // if (res.status === 200) {
          //   this.$message({
          //     showClose: true,
          //     message: '编辑成功',
          //     type: 'success'
          //   });
          //   this.loading = false;
          //   this.drawer = false;
          //   this.$emit('getSelectRole');
          // } else {
          //   this.$message({
          //     showClose: true,
          //     message: res.msg,
          //     type: 'error'
          //   });
          //   this.loading = false;
          // }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    // -------------------------------------------------------------
    // 编辑权限
    editJurisdiction() {
      this.$refs.jurisdiction.openJurisdiction(this.ruleForm.id, true)
    },
    cancel() {
      this.$emit('getSelectRole');
      this.drawer = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.editRole {
  .el-form {
    margin-bottom: 40px;
  }

  .setting {
    .jurisdiction {
      margin-bottom: 40px;

      .title {
        span {
          font-size: 14px;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .name {
        margin-top: 10px;

        span {
          display: inline-block;
          margin: 0 5px;
          font-size: 14px;
        }
      }
    }
  }
  ::v-deep .el-button--primary {
    margin-left: 30px;
  }
}
</style>