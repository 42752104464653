<template>
  <div class="addProjects">
    <div class="header">
      <div class="tablistbox">
        <a
          href="javascript:void(0);"
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index)"
          :key="index"
          v-for="(item, index) in tablist"
          >{{ item.name }}</a
        >
      </div>
      <addProject :is="currentView"></addProject>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddProjects',
  components: {
    addProject: () => import('./addProject/addProject.vue'),
    addRace: () => import('./addProject/addRace.vue'),
    player: () => import('@/components/eventManagement/competitionProject/addProject/player.vue'),
    grouping: () => import('./addProject/grouping.vue')
  },
  data() {
    return {
      isactive: sessionStorage.getItem('isactive') ? sessionStorage.getItem('isactive') : 0,
      tablist: [
        { name: '新建项目' },
        { name: '赛程设置' },
        { name: '选手信息' },
        { name: '分组管理' },
      ],
      currentView: sessionStorage.getItem('currentView') ? sessionStorage.getItem('currentView') : 'addProject'
    };
  },
  methods: {
    // --------------------------
    handelchange(index) {
      this.isactive = index;
      if (index === 0) {
        this.currentView = 'addProject';
        sessionStorage.setItem('currentView', 'addProject');
        sessionStorage.setItem('isactive', 0);
      } else if (index === 1) {
        this.currentView = 'addRace';
        sessionStorage.setItem('currentView', 'addRace');
        sessionStorage.setItem('isactive', 1);
      } else if (index === 2) {
        this.currentView = 'player';
        sessionStorage.setItem('currentView', 'player');
        sessionStorage.setItem('isactive', 2);
      } else if (index === 3) {
        this.currentView = 'grouping';
        sessionStorage.setItem('currentView', 'grouping');
        sessionStorage.setItem('isactive', 3);
      }
    },
  },
  beforeDestroy() {
    if (this.$route.path !== '/home/competitionProject/addProjects') {
      sessionStorage.removeItem("currentView");
      sessionStorage.removeItem("isactive");
      sessionStorage.removeItem("competitionId");
    }
  }
}
</script>

<style lang="scss" scoped>
.addProjects {
  // margin: 0 20px;
  position: relative;
  margin-bottom: 20px;
  .tablistbox {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    a {
      color: #555;
      height: 55px;
      line-height: 55px;
      font-size: 14px;
      margin-right: 100px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }
    .active {
      position: relative;
      height: 55px;
      line-height: 55px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      border-bottom: 3px solid #06206b;
    }
  }
}
</style>
