import request from '@/utils/request'

// 查看场地列表分页
export function getSelectSite(data) {
  return request({
    url: '/site-management/selectSite',
    method: 'post',
    data
  });
}

// 新增场地
export function addSite(data) {
  return request({
    url: '/site-management/addSite',
    method: 'post',
    data
  });
}

// 根据场地id删除场地
export function deleteSite(params) {
  return request({
    url: '/site-management/deleteSite',
    method: 'get',
    params
  });
}

// 根据id查看场地详情
export function getSelectSiteDetails(params) {
  return request({
    url: '/site-management/selectSiteDetails',
    method: 'get',
    params
  });
}

// 编辑场地
export function updateSite(data) {
  return request({
    url: '/site-management/updateSite',
    method: 'post',
    data
  });
}

// 查看所有设备下拉框
export function getSelectAllDevice(params) {
  return request({
    url: '/device-management/selectAllDevice',
    method: 'get',
    params
  });
}