<template>
  <!-- 新建场地 -->
  <div class="addRace">
    <div class="topBox">
      <div class="proBox">新建场地</div>
    </div>
    <div>
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
        label-position="right"
      >
        <el-form-item label="场地名称" prop="name">
          <el-input
            v-model.trim="formData.name"
            placeholder="请输入场地名称"
            :style="{ width: '30%' }"
            maxlength="45"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="场地城市" prop="city">
          <el-select
            v-model="formData.city"
            placeholder="请选择场地城市"
            clearable
            filterable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in cityOptions"
              :key="index"
              :label="item.name"
              :value="item.name"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="场地地址" prop="address">
          <el-input
            v-model.trim="formData.address"
            placeholder="请输入场地地址"
            :style="{ width: '30%' }"
            maxlength="45"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="靶位数量" prop="count">
          <el-input
            v-model="formData.count"
            :disabled="true"
            placeholder="请添加靶位"
            :style="{ width: '30%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label-width="45px" label="">
          <div class="titleBox">
            <div class="div1Box">靶位编号</div>
            <div>设备编号</div>
            <el-button
              type="warning"
              size="mini"
              style="margin-left: 20px"
              @click="addSite"
              >添加</el-button
            >
          </div>
          <div v-if="this.formData.siteTargetPositionList.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.siteTargetPositionList"
              :key="index"
            >
              <div class="div1Box1">{{ item.targetName }}</div>
              <div class="div1Box2">
                {{ item.deviceName }}
                <!-- <el-input
                  v-model="item.number"
                  placeholder="请输入靶位数量"
                  style="border: none; width: 100%; height: 90%; padding: 0"
                /> -->
              </div>
            </div>
          </div>
          <div class="contentBox1" v-else>暂无信息</div>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <AddDevice
      ref="addDeviceRef"
      :groupList2="groupList2"
      :changesite="changesite"
    ></AddDevice>
  </div>
</template>

<script>
import { addSite } from '@/api/targetSite/index.js'
import AddDevice from './addDevice.vue'
export default {
  name: 'AddRace',
  inject: ['reload'],
  components: {
    AddDevice
  },
  props: {},
  data() {
    return {
      formData: {
        name: '',
        city: '',
        address: null,
        count: null,
        siteTargetPositionList: []
      },
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入场地名称',
          trigger: 'blur'
        }],
        city: [{
          required: true,
          message: '请选择场地城市',
          trigger: 'change'
        }],
        address: [{
          required: true,
          message: '请输入场地地址',
          trigger: 'blur'
        }],
        count: [{
          required: true,
          message: '请添加靶位',
          trigger: 'blur'
        }],
      },
      groupList2: [],
      cityOptions: []
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getAllProvinceAndCityList();
  },
  methods: {
    getAllProvinceAndCityList() {     // 获取全国所有省份和城市的列表
      var that = this;
      this.$http.get('https://restapi.amap.com/v3/config/district', {   // 高德地图的API
        params: {
          key: '09c94293822de401f62911d40c755415',   // 这个是申请的 Key，读者自行写入
          keywords: '中国',
          subdistrict: 2,
          extensions: 'base'
        }
      }).then((response) => {
        // console.log(response);
        that.cityOptions = [];
        for (let i = 0; i < response.data.districts[0].districts.length; i++) {
          for (let j = 0; j < response.data.districts[0].districts[i].districts.length; j++) {
            that.cityOptions.push(response.data.districts[0].districts[i].districts[j]);
          }
        }
        // console.log('城市', that.cityOptions);
      });
    },


    // 场地数量输入事件
    // sumClick() {
    //   this.formData.siteTargetPositionList = [];
    //   for (let i = 0; i < parseInt(this.formData.count); i++) {
    //     let obj = {
    //       name: i + 1,
    //       number: ""
    //     };
    //     this.formData.siteTargetPositionList.push(obj);
    //   }
    // },
    // 子传父
    changesite(val) {
      this.formData.siteTargetPositionList = val;
      this.formData.count = this.formData.siteTargetPositionList.length;
    },
    // 打开添加设备弹层
    addSite() {
      if (this.formData.siteTargetPositionList.length > 0) {
        this.groupList2 = [];
        this.formData.siteTargetPositionList.forEach(item => {
          this.groupList2.push(item.deviceId);
        });
        // console.log(this.groupList2);
      }
      setTimeout(() => {
        this.$refs.addDeviceRef.openAdd();
      }, 100)
    },

    // ---------------------------------
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        // TODO 提交表单
        if (this.formData.count === 0) {
          this.formData.count = null
          this.$message({
            type: 'warning',
            message: '请添加设备',
            showClose: true
          })
          return
        }
        // console.log(this.formData);
        // console.log(this.val);
        this.loading = true;
        const res = await addSite(this.formData)
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '新增成功!'
          });
          this.loading = false;
          this.$router.push('/home/targetSite');
          this.reload(); // 需要刷新页面
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '新增失败!' + res.msg
          });
          this.loading = false;
        }
      })
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
      this.$router.push('/home/targetSite');
    },
  }
}
</script>

<style lang="scss" scoped>
.addRace {
  .topBox {
    width: calc(100% - 50px);
    height: 60px;
    margin-bottom: 40px;
    margin-left: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    .proBox {
      cursor: pointer;
      width: 70px;
      height: 60px;
      line-height: 80px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      box-sizing: border-box;
      border-bottom: 1px solid #06206b;
    }
  }
  .fileBox {
    position: relative;
    .addBtnBox {
      position: absolute;
      left: calc(145px + 30%);
      bottom: 70px;
      width: 70px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background: #e3620b;
      border-color: #e3620b;
      border-radius: 2px;
    }
  }
  .item1 {
    margin-bottom: 10px;
  }
  .titleBox,
  .contentBox {
    display: flex;
    div {
      width: 260px;
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #7d7d7d;
      background: #ffffff;
      padding-left: 10px;
      box-sizing: border-box;
      border: 1px solid #dddddd;
    }
    .div1Box {
      border-right: none;
    }
  }
  .contentBox1 {
    width: 520px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #dddddd;
    box-sizing: border-box;
    border: 1px solid #dddddd;
    border-top: none;
  }

  .contentBox {
    div {
      border-top: none;
      ::v-deep.el-input__inner {
        border: none;
        height: 95%;
        padding: 0;
      }
    }
    .div1Box1 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      border-right: none;
    }
    .div1Box2 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-form-item__content {
      margin-left: 14% !important;
      .resBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #eaefff;
        border-color: #eaefff;
        border-radius: 2px;
        span {
          color: #06206b;
        }
      }
      .subBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #06206b;
        border-color: #06206b;
        border-radius: 2px;
        margin-left: 50px;
      }
    }
  }
}
</style>
