<template>
  <!-- 新建赛程 -->
  <div class="addRace">
    <div class="topBox">
      <div class="proBox">新建赛程</div>
    </div>
    <div>
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="100px"
      >
        <el-form-item
          label-width="128px"
          label="比赛名称（项目）"
          prop="competitionId"
        >
          <el-select
            v-model="formData.competitionId"
            placeholder="请选择比赛名称（项目）"
            clearable
            :style="{ width: '30%' }"
          >
            <el-option
              v-for="(item, index) in CompetitionDropList"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="fileBox">
          <el-row
            v-for="(item, index) in formData.scheduleDtoList"
            :key="index"
            :gutter="15"
            class="rowBox"
          >
            <div v-if="item.scheduleName !== 4 && item.scheduleName !== 5">
              <el-form-item class="item1" label-width="135px" label="赛程阶段">
                <el-select
                  v-model="item.scheduleName"
                  placeholder="请选择赛程阶段"
                  clearable
                  :style="{ width: '30%' }"
                >
                  <el-option
                    v-for="(item, index) in options"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.disabled"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                label-width="135px"
                v-if="item.scheduleName !== 6"
                label="射击发数"
              >
                <el-input
                  v-model="item.shootCount"
                  placeholder="请输入射击发数"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label-width="135px"
                v-if="item.scheduleName !== 6"
                label="记成绩发数"
              >
                <el-input
                  v-model="item.scoreCount"
                  placeholder="请输入记成绩发数"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input>
              </el-form-item>
              <el-form-item
                label-width="135px"
                v-if="item.scheduleName !== 6"
                label="比赛限时(分钟)"
              >
                <el-input
                  v-model="item.ompetitionTime"
                  placeholder="请输入比赛限时"
                  clearable
                  :style="{ width: '30%' }"
                >
                </el-input>
              </el-form-item>
              <el-form-item label-width="135px" label="比赛时间">
                <el-date-picker
                  type="datetimerange"
                  v-model="item.timeStol"
                  placement="bottom-start"
                  format="yyyy-MM-dd HH:mm"
                  value-format="yyyy-MM-dd HH:mm"
                  :style="{ width: '30%' }"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  range-separator="至"
                  clearable
                ></el-date-picker>
              </el-form-item>
            </div>
            <el-button
              class="addBtnBox"
              v-if="index + 1 === formData.scheduleDtoList.length"
              type="primary"
              @click="addRow"
              >新增</el-button
            >
          </el-row>
        </div>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="resetForm">取消</el-button>
          <el-button
            type="primary"
            class="subBox"
            @click="submitForm"
            :loading="loading"
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addSchedule, getSelectCompetitionDrop } from '@/api/eventManagement/raceSchedule/index.js'
export default {
  name: 'AddRace',
  inject: ['reload'],
  components: {},
  props: {},
  data() {
    return {
      formData: {
        competitionId: null,
        scheduleDtoList: [
          {
            endTime: "", // 结束时间
            ompetitionTime: null, // 比赛限时，单位s
            scheduleId: null, // 赛程id
            scheduleName: null, // 1资格赛2半决赛3决赛
            shootCount: null, // 射击发数
            scoreCount: null, // 每多少发记一次成绩
            startTime: "", // 开始时间
            timeStol: null // 时间段 
          }
        ]
      },
      loading: false,
      rules: {
        competitionId: [{
          required: true,
          message: '请选择比赛名称（项目）',
          trigger: 'change'
        }],
        scheduleName: [{
          required: true,
          message: '请输入赛程阶段',
          trigger: 'blur'
        }],
        timeStol: [{
          required: true,
          message: '不能为空',
          trigger: 'change'
        }]
      },
      options: [
        {
          id: 1,
          name: '淘汰赛'
        },
        {
          id: 2,
          name: '资格赛'
        },
        {
          id: 3,
          name: '决赛'
        },
        {
          id: 6,
          name: '夺冠赛'
        },
      ],
      CompetitionDropList: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectCompetitionDrop();
  },
  methods: {
    // 比赛名列表
    async getSelectCompetitionDrop() {
      const res = await getSelectCompetitionDrop();
      console.log(res);
      if (res.status === 200) {
        this.CompetitionDropList = res.data;
      }
    },
    // 新增赛程阶段
    addRow() {
      // console.log(this.formData.scheduleDtoList);
      this.formData.scheduleDtoList.push({
        endTime: "", // 结束时间
        ompetitionTime: null, // 比赛限时，单位s
        scheduleId: null, // 赛程id
        scheduleName: null, // 1资格赛2半决赛3决赛
        shootCount: null, // 射击发数
        scoreCount: null, // 每多少发记一次成绩
        startTime: "", // 开始时间
        timeStol: null // 时间段 
      });
    },

    // ---------------------------------
    async submitForm() {
      this.$refs['elForm'].validate(valid => {
        if (!valid) return
        // TODO 提交表单
      })
      this.formData.scheduleDtoList.forEach(item => {
        item.startTime = item.timeStol[0];
        item.endTime = item.timeStol[1];
        item.ompetitionTime = item.ompetitionTime ? item.ompetitionTime * 60 : null
      })
      // console.log(this.formData);
      // console.log(this.val);
      this.loading = true;
      const res = await addSchedule(this.formData)
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '新增成功!'
        });
        this.loading = false;
        this.$router.push('/home/raceSchedule');
        this.reload(); // 需要刷新页面
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: '新增失败!' + res.msg
        });
        this.loading = false;
      }
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
      this.$router.push('/home/raceSchedule');
    },
  }
}
</script>

<style lang="scss" scoped>
.addRace {
  .topBox {
    width: calc(100% - 50px);
    height: 60px;
    margin-bottom: 40px;
    margin-left: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #ddd;
    .proBox {
      cursor: pointer;
      width: 70px;
      height: 60px;
      line-height: 80px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      box-sizing: border-box;
      border-bottom: 1px solid #06206b;
    }
  }
  .fileBox {
    position: relative;
    .addBtnBox {
      position: absolute;
      left: calc(145px + 30%);
      top: 3px;
      width: 70px;
      height: 30px;
      line-height: 30px;
      padding: 0;
      background: #e3620b;
      border-color: #e3620b;
      border-radius: 2px;
    }
  }
  .item1 {
    margin-bottom: 10px;
  }
  .btnBox {
    margin-top: 50px;
    ::v-deep .el-form-item__content {
      margin-left: 14% !important;
      .resBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #eaefff;
        border-color: #eaefff;
        border-radius: 2px;
        span {
          color: #06206b;
        }
      }
      .subBox {
        width: 70px;
        height: 30px;
        line-height: 30px;
        padding: 0;
        background: #06206b;
        border-color: #06206b;
        border-radius: 2px;
        margin-left: 50px;
      }
    }
  }
}
</style>
