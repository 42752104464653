<template>
  <div class="userList">
    <!-- 用户管理 -->
    <div class="searchBtn">
      <el-select
        v-model="paging.roleId"
        placeholder="请选择角色"
        @change="search"
      >
        <el-option
          v-for="item in RolesList"
          :key="item.roleId"
          :label="item.roleName"
          :value="item.roleId"
        >
        </el-option>
      </el-select>
      <!-- </div> -->
      <!-- <div class="btn"> -->
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="add">新增</el-button>
      <!-- </div> -->
    </div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        size="small"
      >
        <el-table-column label="编号" type="index" width="150">
        </el-table-column>
        <el-table-column prop="name" label="用户名"> </el-table-column>
        <el-table-column label="角色">
          <template slot-scope="scope">
            <span v-for="item in scope.row.roleList" :key="item.roleId">
              {{ item.roleName }} &nbsp;&nbsp;</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleteUser(scope.row)"
              >删除</el-button
            >
            <el-button type="text" size="small" @click="updatePWD(scope.row)"
              >重置密码</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="paging.p"
        :page-size="paging.ps"
        layout="total, prev, pager, next, jumper"
        :total="paging.total"
      >
      </el-pagination>
    </div>
    <EditUserVue ref="editChild" @getSelect="getSelect"></EditUserVue>
    <AddUserVue ref="addChild" @getSelect="getSelect"></AddUserVue>
  </div>
</template>

<script>
import { getSelectRole } from '@/api/systemManagement/roleManagement/index.js'
import { getSelect, resetPwd, deleteUser } from '@/api/systemManagement/userManagement/index.js'
import AddUserVue from './AddUser.vue'
import EditUserVue from './EditUser.vue'
export default {
  name: 'UserList',
  components: {
    AddUserVue, EditUserVue
  },
  created() {
    this.getSelect();
    this.getSelectRole();
  },
  data() {
    return {
      paging: {
        p: 1,
        ps: 13,
        roleId: null,
        total: 0
      },
      tableData: [], //用户列表
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')), // 用户信息
      RolesList: [], // 角色列表
      loading: true,
    }
  },
  methods: {
    // 获取用户列表
    async getSelect() {
      this.loading = true;
      const res = await getSelect({ page: this.paging.p, size: this.paging.ps, roleId: this.paging.roleId });
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getSelect()
    },
    // 新增
    add() {
      // console.log('111')
      this.$refs.addChild.openAdd();
    },
    // 点击搜索
    async search() {
      this.paging.p = 1;
      this.getSelect();
    },
    // 获取角色列表
    async getSelectRole() {
      const res = await getSelectRole();
      // console.log(res);
      if (res.status === 200) {
        this.RolesList = res.data;
        this.RolesList = [{ roleId: null, roleName: '全部' }, ...this.RolesList]
      }
    },
    // 编辑
    edit(val) {
      this.$refs.editChild.openEdit(val)
    },
    // 重置密码
    updatePWD(val) {
      console.log(val);
      this.$confirm(`您正在重置 ${val.name} 的密码`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        type: 'warning'
      }).then(async () => {
        const res = await resetPwd({ userId: val.id });
        console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '重置密码成功!'
          });
          if (val.id === this.userInfo.id) {
            this.$router.push('/')
          }
          this.getSelect();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.msg
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消重置密码！'
        });
      });
    },
    // 删除用户
    deleteUser(val) {
      // console.log(val);
      this.$confirm(`确认删除该用户(${val.name})?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteUser({ userId: val.id });
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.paging.p = 1;
          this.getSelect();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除!'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.userList {
  padding: 20px;

  // .search {
  //     display: flex;
  //     width: 100%;

  //     // .inputContent {

  //     .el-input,
  //     .el-select,
  //     .el-cascader {
  //         width: 150px;
  //         margin-right: 30px;
  //     }

  //     // }

  //     // .btn {
  //     .el-button {
  //         margin-left: 40px;
  //         display: inline-block;
  //     }

  //     // }

  // }

  .tableContent {
    margin-top: 20px;

    // ::v-deep.el-table .el-table__body .el-table__cell {
    //     height: 32px !important;
    //     padding: 0px !important;
    //     line-height: 32px !important;
    // }

    // ::v-deep.el-table th.el-table__cell {
    //     height: 35px !important;
    //     padding: 0px !important;
    //     line-height: 35px !important;
    // }

    // .el-table--border {
    //   border-bottom: 1px solid #ebeef5;
    // }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>