import request from '../utils/request'
// 用户登录
export function login(data) {
  return request({
    url: '/login/login',
    method: 'post',
    data
  });
}

// 修改密码
export function updatePwd(data) {
  return request({
    url: '/sys-user/updatePwd',
    method: 'post',
    data
  });
}