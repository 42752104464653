import request from '@/utils/request';

// 获取用户列表
export function getSelect(params) {
  return request({
    url: '/sys-user/select',
    method: 'get',
    params
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: '/sys-user/add',
    method: 'post',
    data
  });
}

// 编辑用户
export function update(data) {
  return request({
    url: '/sys-user/update',
    method: 'post',
    data
  });
}

// 删除用户
export function deleteUser(params) {
  return request({
    url: '/sys-user/deleteUser',
    method: 'get',
    params
  });
}

// 重置密码
export function resetPwd(params) {
  return request({
    url: '/sys-user/resetPwd',
    method: 'get',
    params
  });
}