<template>
  <div class="addDevice">
    <el-dialog
      title="添加设备"
      :visible.sync="dialogVisible"
      width="40%"
      top="10vh"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <div class="contBox">
        <div class="searchBtnBox">
          <div
            :class="index == isactive ? 'active' : ''"
            @click="handelchange(index, item)"
            v-for="(item, index) in classificationList"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <el-transfer
          filterable
          :titles="['全部设备', '已选设备']"
          filter-placeholder="请输入设备编号"
          v-model="groupList"
          :data="data1"
          :props="{
            key: 'id',
            label: 'number',
          }"
        >
        </el-transfer>
      </div>
      <div slot="footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="handelConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getSelectAllDevice } from '@/api/targetSite/index.js'
export default {
  components: {},
  props: ['changesite', 'groupList2'],
  data() {
    return {
      dialogVisible: false,
      classificationList: [
        {
          id: null,
          name: '全部'
        },
        {
          id: 1,
          name: '气步枪'
        },
        {
          id: 2,
          name: '气手枪'
        }
      ],
      isactive: 0,
      data1: [],
      groupList1: [],
      groupList: [],
    }
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() { },
  methods: {
    // 查看所有设备
    async getSelectAllDevice(val) {
      const res = await getSelectAllDevice({ type: val });
      // console.log(res);
      if (res.status === 200) {
        this.data1 = res.data;
      }
    },
    // 打开当前模态框
    openAdd() {
      this.groupList = this.groupList2;
      // console.log(this.groupList, this.groupList2);
      this.getSelectAllDevice(null);
      this.dialogVisible = true;
    },
    // 筛选事件
    handelchange(index, item) {
      this.isactive = index;
      // console.log(item);
      this.getSelectAllDevice(item.id);
    },


    close() {
      this.dialogVisible = false;
    },
    handelConfirm() {
      this.groupList1 = [];
      this.data1.forEach(item => {
        for (let i = 0; i < this.groupList.length; i++) {
          if (item.id === this.groupList[i]) {
            let obj = {
              targetName: i + 1,
              deviceName: item.number,
              deviceId: item.id
            };
            this.groupList1.push(obj);
          }
        }
      });
      // console.log(this.groupList1);
      this.changesite(this.groupList1);
      this.close();
    },
  }
}

</script>
<style lang="scss" scoped>
.addDevice {
  .contBox {
    padding: 15px;
    border-radius: 2px;
    border: 1px solid #dddddd;
  }
  .searchBtnBox {
    height: 32px;
    display: flex;
    margin-bottom: 20px;
    div {
      cursor: pointer;
      width: 75px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      background: #fff;
      margin-right: 50px;
    }
    .active {
      width: 75px;
      height: 32px;
      line-height: 32px;
      background: #06206b;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
  ::v-deep .el-transfer-panel {
    width: 42%;
    height: 450px;
    .el-transfer-panel__body {
      height: 410px;
    }
    .el-transfer-panel__list {
      height: 350px;
    }
  }
  ::v-deep .el-transfer__buttons {
    width: calc(16% - 60px);
    .el-button {
      margin: 0 !important;
      &:nth-child(1) {
        margin-bottom: 20px !important;
      }
    }
  } //滚动条的宽度
  ::v-deep.el-dialog__body {
    margin-top: 20px !important;
  }
  ::v-deep.el-dialog__footer {
    margin-top: 30px;
    .el-button--default {
      background: #eaefff;
      color: #06206b;
      border: none;
    }
    .el-button--primary {
      margin-left: 40px !important;
      background: #06206b;
      border: none;
    }
  }
}
</style>
