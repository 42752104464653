<template>
  <div class="login-container">
    <div class="logoBox">
      <div class="logoBj"></div>
      <div class="logoText">成都中成荣耀体育科技有限公司</div>
    </div>
    <el-form class="formbox">
      <div class="nameBox">射击比赛管理系统</div>
      <div class="loginBox">
        <el-input
          class="userinput"
          v-model="ruleForm.account"
          prefix-icon="el-icon-user"
          placeholder="请输入账号"
          maxlength="12"
        ></el-input>
        <el-input
          class="userinput userinput1"
          v-model="ruleForm.pwd"
          prefix-icon="el-icon-unlock"
          placeholder="请输入密码"
          show-password
          maxlength="16"
        ></el-input>
        <el-checkbox class="checkBox" v-model="checked">记住密码</el-checkbox>
        <el-button
          :loading="loading"
          class="btnBox"
          type="primary"
          @click="handleLogin"
          >登录</el-button
        >
      </div>
    </el-form>
  </div>
</template>

<script>
import { login } from '@/api/login'
export default {
  name: 'Login',
  comments: {
  },
  data() {
    return {
      ruleForm: {
        account: '',
        pwd: ''
      },
      loading: false,
      checked: false
    }
  },
  watch: {

  },
  mounted() {
    this.getCookie();
  },
  methods: {
    // 登录请求
    async handleLogin() {
      this.loading = true;
      const Base64 = require('js-base64').Base64;
      /* ------ 账号密码的存储 ------ */
      if (this.checked) {
        let pwd = Base64.encode(this.ruleForm.pwd); // base64加密
        this.setCookie(this.ruleForm.account, pwd, 7);
      } else {
        this.setCookie("", "", -1); // 修改2值都为空，天数为负1天就好了
      }
      // console.log(JSON.stringify(this.ruleForm));
      // const exStr = Base64.encode(JSON.stringify(this.ruleForm));  //加密
      // const exStr = Base64.encode(this.ruleForm.pwd);  //加密
      const exStr = this.$md5(this.ruleForm.pwd)
      // console.log(exStr);
      const res = await login({
        'name': this.ruleForm.account,
        'pwd': exStr
      })
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '登录成功',
          type: 'success'
        });
        sessionStorage.setItem('usertoken', res.data.token)
        localStorage.setItem('usertoken', res.data.token)
        sessionStorage.setItem('userId', res.data.id)
        sessionStorage.setItem('userInfo', JSON.stringify(res.data))
        this.$store.dispatch('setToken', res.data.token)
        setTimeout(() => {
          this.$router.push('/home')
        }, 500);
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
      // this.$router.push('/home')
      this.loading = false;
    },
    // 设置cookie
    setCookie(account, pwd, days) {
      let date = new Date(); // 获取时间
      date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * days); // 保存的天数
      // 字符串拼接cookie
      window.document.cookie =
        "account" + "=" + account + ";path=/;expires=" + date.toGMTString();
      window.document.cookie =
        "pwd" + "=" + pwd + ";path=/;expires=" + date.toGMTString();
    },

    // 读取cookie 将用户名和密码回显到input框中
    getCookie() {
      const Base64 = require('js-base64').Base64;
      if (document.cookie.length > 0) {
        let arr = document.cookie.split("; "); //分割成一个个独立的“key=value”的形式
        for (let i = 0; i < arr.length; i++) {
          let arr2 = arr[i].split("="); // 再次切割，arr2[0]为key值，arr2[1]为对应的value
          if (arr2[0] === "account") {
            this.ruleForm.account = arr2[1];
          } else if (arr2[0] === "pwd") {
            this.ruleForm.pwd = Base64.decode(arr2[1]);// base64解密
            this.checked = true;
          }
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.formbox .el-input__inner {
  padding-bottom: 20px;
  padding-top: 20px;
  height: 50px;
  line-height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.login-container {
  position: relative;
  width: 1920px;
  height: 100vh;
  background-image: url("~@/assets/sjbj.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  .logoBox {
    position: absolute;
    top: 36px;
    left: 56px;
    .logoBj {
      width: 308px;
      height: 65px;
      background-image: url("~@/assets/logo.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .logoText {
      font-size: 22px;
      color: #06206b;
    }
  }
  .formbox {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -250px;
    background-image: url("~@/assets/loginbj.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .nameBox {
      position: absolute;
      top: -20%;
      left: 50%;
      margin-left: -250px;
      width: 500px;
      height: 46px;
      font-size: 46px;
      font-family: MFJianHei_Noncommercial-Regular, MFJianHei_Noncommercial;
      font-weight: 400;
      text-align: center;
      color: #06206b;
      line-height: 46px;
    }
    .loginBox {
      width: 240px;
      height: 100%;
      margin-left: 130px;
      .userinput {
        width: 240px;
        height: 40px;
        background: #ffffff;
        overflow: hidden;
        border-radius: 20px;
        border: 1px solid #275cf4;
        margin-top: 142px;
      }
      .userinput1 {
        margin-top: 15px;
      }
      .checkBox {
        width: 240px;
        padding-left: 65px;
        margin-top: 10px;
        box-sizing: border-box;
        ::v-deep .el-checkbox__inner {
          zoom: 130%;
        }
      }
      .btnBox {
        width: 240px;
        height: 40px;
        line-height: 40px;
        padding: 0;
        background: #06206b;
        border-radius: 20px;
        margin-top: 25px;
      }
      ::v-deep .el-input__inner {
        width: 240px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }
}
</style>
