<template>
  <div class="targetSite">
    <!--场地靶位管理 -->
    <div v-if="this.$route.path === '/home/targetSite'">
      <div class="searchBtn">
        <el-input
          placeholder="请输入场地名称"
          v-model.trim="paging.data.name"
          clearable
        >
        </el-input>
        <el-select
          v-model="paging.data.status"
          placeholder="请选择场地状态"
          @change="search"
        >
          <el-option
            v-for="item in stateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button type="primary" size="small" @click="add">新增</el-button>
      </div>
      <div class="tableContent">
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          size="small"
        >
          <el-table-column prop="name" label="场地名称" width="100">
          </el-table-column>
          <el-table-column prop="city" label="场地城市" width="100">
          </el-table-column>
          <el-table-column prop="address" label="场地地址"> </el-table-column>
          <el-table-column prop="count" label="靶位数量" width="120">
          </el-table-column>
          <el-table-column label="场地状态" width="180">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">待使用</span>
              <span v-if="scope.row.status === 2">使用中</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="deleteSite(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="paging.page"
          :page-size="paging.size"
          layout="total, prev, pager, next, jumper"
          :total="paging.total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="this.$route.path === '/home/targetSite/addField'">
      <AddField></AddField>
    </div>
    <div v-else>
      <EditField></EditField>
    </div>
  </div>
</template>
</template>

<script>
import { getSelectSite, deleteSite } from '@/api/targetSite/index.js'
import AddField from './addField.vue'
import EditField from '@/components/targetSite/editField.vue'
export default {
  name: 'TargetSite',
  components: {
    AddField, EditField
  },
  created() {
    this.getSelectSite();
  },
  data() {
    return {
      paging: {
        data: {
          name: "",
          status: null
        },
        page: 1,
        size: 13,
        total: 0
      },
      loading: true,
      stateList: [
        {
          label: '全部',
          value: null
        },
        {
          label: '待使用',
          value: 1
        },
        {
          label: '使用中',
          value: 2
        },
      ],
      tableData: [], // 场地列表
    }
  },
  methods: {
    // 获取场地列表
    async getSelectSite() {
      this.loading = true;
      const res = await getSelectSite(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        // console.log(this.paging.total)
        this.loading = false;
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectSite()
    },
    // 点击搜索
    async search() {
      this.paging.page = 1;
      this.getSelectSite();
    },
    // 删除场地
    deleteSite(val) {
      this.$confirm('确认删除该场地?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteSite({ siteId: val });
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSelectSite();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增
    add() {
      // console.log(111)
      this.$router.push('/home/targetSite/addField');
    },
    // 编辑
    edit(val) {
      this.$router.push('/home/targetSite/editField');
      sessionStorage.setItem('siteId', val);
    }
  }
}
</script>

<style lang="scss" scoped>
.targetSite {
  padding: 20px;

  .litDelBox {
    display: flex;
    // justify-content: center;
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: none;
    padding-left: 10px;
    text-align: left;
    border-top: 1px solid #e6ebff;
    // border-right: none;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .el-select--mini {
      width: 100%;
      height: 100%;
      .el-input--mini {
        height: 100%;
        .el-input__inner {
          height: 60%;
          line-height: 100%;
          border: none;
        }
      }
    }
  }
  .litDelBox1 {
    border-top: none;
  }

  .tableContent {
    margin-top: 20px;

    // ::v-deep.el-table .el-table__body .el-table__cell {
    //     height: 32px !important;
    //     padding: 0px !important;
    //     line-height: 32px !important;
    // }

    // ::v-deep.el-table th.el-table__cell {
    //     height: 35px !important;
    //     padding: 0px !important;
    //     line-height: 35px !important;
    // }

    /* .el-table--border {
      border-bottom: 1px solid #ebeef5;
    } */
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>


