import request from '@/utils/request';

// 新增角色
export function addRoles(data) {
  return request({
    url: '/sys-role/add',
    method: 'post',
    data
  });
}

// 查询角色列表
export function getSelectRole() {
  return request({
    url: '/sys-role/selectRole',
    method: 'get'
  });
}

// 根据ID删除角色
export function deletedRole(data) {
  return request({
    url: '/sys-role/deleted',
    method: 'post',
    data
  });
}

// 修改角色信息
export function updateRole(data) {
  return request({
    url: '/sys-role/update',
    method: 'post',
    data
  });
}
// 修改角色权限菜单
export function setRoleMenu(data) {
  return request({
    url: '/sys-role/setAuth',
    method: 'post',
    data
  });
}
// 查询所有菜单
export function getSelectMenu() {
  return request({
    url: '/sys-role/selectMenu',
    method: 'get'
  });
}
// 根据ID查询角色菜单
export function getIdMenu(params) {
  return request({
    url: '/sys-role/selectAuth',
    method: 'get',
    params: params
  });
}