<template>
  <!-- 总决赛详情 -->
  <div class="championshipDetails">
    <div class="topBox">
      <div class="nameBox">
        <span class="span1Box">比赛名称：</span>
        <span class="span2Box">{{ groupData.comName }}</span>
      </div>
      <div class="typeBox">
        <span class="span1Box">比赛类型：</span>
        <span class="span2Box">{{ groupData.comType }}</span>
      </div>
      <div class="timeBox">
        <span class="span1Box">比赛时间：</span>
        <span class="span2Box">{{ groupData.comStartTime }}</span>
      </div>
      <div class="timeBox">
        <span class="span1Box">比赛状态：</span>
        <span class="span2Box">
          <span v-if="groupData.schuduleStatus === 1">
            <span class="ydBox ydBox1"></span>
            <span class="nameBox">未开始</span>
          </span>
          <!-- <span v-if="groupData.schuduleStatus === 2">
            <span class="ydBox ydBox4"></span>
            <span class="nameBox nameBox4">已分组</span>
          </span> -->
          <span v-if="groupData.schuduleStatus === 2">
            <span class="ydBox ydBox2"></span>
            <span class="nameBox nameBox2">进行中</span>
          </span>
          <span v-if="groupData.schuduleStatus === 3">
            <span class="ydBox ydBox3"></span>
            <span class="nameBox nameBox3">已结束</span>
          </span>
        </span>
      </div>
    </div>
    <div class="midBox">
      <div class="bwxxBox">靶位信息</div>
      <div class="flBox">
        <div>
          <span class="ydBox ydBox0"></span>
          <span class="nameBox nameBox0">未开始</span>
        </div>
        <div>
          <span class="ydBox ydBox1"></span>
          <span class="nameBox nameBox1">准备</span>
        </div>
        <div>
          <span class="ydBox ydBox2"></span>
          <span class="nameBox nameBox2">试射</span>
        </div>
        <div>
          <span class="ydBox ydBox3"></span>
          <span class="nameBox nameBox3">记分赛</span>
        </div>
        <button
          v-if="updateData.status > 1 && updateData.status < 5"
          type="primary"
          size="small"
          @click="updateBtn"
        >
          <span v-if="updateData.status === 2">开始准备</span>
          <span v-if="updateData.status === 3">开始试射</span>
          <span v-if="updateData.status === 4">开始记分</span>
        </button>
        <button
          type="primary"
          style="margin-left: 20px"
          size="small"
          @click="toupingBtn"
        >
          投屏
        </button>
        <!-- <div class="timeBox">
          <span class="jsBox">倒计时：</span>
          <span class="tiBox">10:00:00</span>
        </div> -->
      </div>
      <div class="playersBox" v-if="data1 && data1.length > 0">
        <div
          class="playBox"
          :class="{ active: currentIndex === index }"
          v-for="(item, index) in data1"
          @click="editClick(item, index)"
          :key="index"
        >
          <div
            class="playerBox"
            :class="{
              LvOne: item.status === 2,
              LvTwo: item.status === 3,
              LvThree: item.status === 4,
            }"
          >
            {{ item.playerName }}
            <img
              v-if="item.playerstatus === 2"
              src="@/assets/jinji.png"
              alt=""
            />
          </div>
          <div
            class="numberBox"
            :class="{
              LvOne1: item.status === 2,
              LvTwo2: item.status === 3,
              LvThree3: item.status === 4,
            }"
          >
            {{ item.targetName }}
          </div>
        </div>
      </div>
      <div class="playersBox playersBox1" v-else>暂无数据</div>
    </div>
    <div class="botBox">
      <div class="titleBox">实时排名</div>
      <div class="tableBox">
        <div class="topBox">
          <div class="xsBox">
            <div>{{ player1.playerName }}</div>
            <div>{{ player1.siteCity }}</div>
            <div>{{ player1.siteName }}</div>
            <div>{{ player1.targetName }}</div>
            <div>{{ player1.company }}</div>
          </div>
          <div></div>
          <div class="xsBox">
            <div>{{ player2.playerName }}</div>
            <div>{{ player2.siteCity }}</div>
            <div>{{ player1.siteName }}</div>
            <div>{{ player2.targetName }}</div>
            <div>{{ player2.company }}</div>
          </div>
        </div>
        <div class="contentBox">
          <div class="leftBox">
            <div class="titBox">
              <div>发序</div>
              <div>环值</div>
              <div>方向</div>
            </div>
            <div v-if="playerData1 && playerData1.length > 0">
              <div
                class="contBox contBox1"
                v-for="(item, index) in playerData1"
                :key="index"
              >
                <div>{{ item.shootCount }}</div>
                <div>{{ item.ringValue }}</div>
                <div>
                  <i v-if="item.direction === 1" class="el-icon-top"></i>
                  <i v-if="item.direction === 2" class="el-icon-bottom"></i>
                  <i v-if="item.direction === 3" class="el-icon-back"></i>
                  <i v-if="item.direction === 4" class="el-icon-right"></i>
                  <i v-if="item.direction === 5" class="el-icon-top-left"></i>
                  <i v-if="item.direction === 6" class="el-icon-top-right"></i>
                  <i
                    v-if="item.direction === 7"
                    class="el-icon-bottom-right"
                  ></i>
                  <i
                    v-if="item.direction === 8"
                    class="el-icon-bottom-left"
                  ></i>
                </div>
              </div>
            </div>
            <div v-else class="contBox">暂无数据</div>
          </div>
          <div class="midBox1">
            <div class="sumBox">{{ player1.score }}</div>
            <div class="vsBox">
              <div class="xBox"></div>
              <div class="vssBox">vs</div>
              <div class="xBox"></div>
            </div>
            <div class="sumBox">{{ player2.score }}</div>
          </div>
          <div class="leftBox">
            <div class="titBox">
              <div>发序</div>
              <div>环值</div>
              <div>方向</div>
            </div>
            <div v-if="playerData2 && playerData2.length > 0">
              <div
                class="contBox contBox1"
                v-for="(item, index) in playerData2"
                :key="index"
              >
                <div>{{ item.shootCount }}</div>
                <div>{{ item.ringValue }}</div>
                <div>
                  <i v-if="item.direction === 1" class="el-icon-top"></i>
                  <i v-if="item.direction === 2" class="el-icon-bottom"></i>
                  <i v-if="item.direction === 3" class="el-icon-back"></i>
                  <i v-if="item.direction === 4" class="el-icon-right"></i>
                  <i v-if="item.direction === 5" class="el-icon-top-left"></i>
                  <i v-if="item.direction === 6" class="el-icon-top-right"></i>
                  <i
                    v-if="item.direction === 7"
                    class="el-icon-bottom-right"
                  ></i>
                  <i
                    v-if="item.direction === 8"
                    class="el-icon-bottom-left"
                  ></i>
                </div>
              </div>
            </div>
            <div v-else class="contBox">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <Prepare
      ref="prepareChild"
      @getSelect="getSelect"
      :currentIndex="currentIndex"
    ></Prepare>
    <Try ref="tryChild" @getSelect="getSelect"></Try>
    <ScoringMatch
      ref="scorChild"
      @getSelect="getSelect"
      @getSelectShootingByGroupName="getSelectShootingByGroupName"
    ></ScoringMatch>
  </div>
</template>
</template>

<script>
import { getSelectShootingByscheduleId, getSelectShootingByGroupName, updateAllGroupStatus } from '@/api/realTime/index.js'
import Prepare from './qualificationDetails/prepare.vue'
import Try from './qualificationDetails/try.vue'
import ScoringMatch from './qualificationDetails/scoringMatch.vue'
import webSocket from '@/api/socket.js'
import urls from '@/utils/url.js'

export default {
  name: 'ChampionshipDetails',
  components: {
    Prepare,
    Try,
    ScoringMatch
  },
  props: {},
  data() {
    return {
      scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
      groupData: {},
      groupNameData: {
        groupName: '',
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      data1: [],
      playerData1: {},
      playerData2: {},
      player1: {},
      player2: {},
      currentIndex: -1, // 记录状态的变量
      isactive: 0,
      updateData: {
        groupName: '',
        scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
        status: 1
      }, // 一键开始数据
    }
  },
  computed: {},
  watch: {},
  created() {
    setTimeout(() => {
      this.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.groupNameData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.updateData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.getSelectShootingByscheduleId();
    }, 200)
  },
  mounted() {
    setTimeout(() => {
      webSocket.setMessageCallback(res => {
        // 在此处进行数据刷新操作即可实现数据发生改变时实时更新数据
        console.log("接收到回信cha", res);
        this.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.groupNameData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.updateData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
        this.getSelectShootingByGroupName();
      })
    }, 1000)
  },
  methods: {
    // 根据赛程id查询实时赛况信息
    async getSelectShootingByscheduleId() {
      console.log('11');
      const res = await getSelectShootingByscheduleId({ scheduleId: this.scheduleId });
      // console.log(res);
      if (res.status === 200) {
        this.groupData = res.data;
        if (res.data.groupNameList.length > 0) {
          this.groupNameData.groupName = res.data.groupNameList[0];
          this.updateData.groupName = res.data.groupNameList[0];
        }
        this.getSelectShootingByGroupName();
      }
    },
    // 查询靶位及实时分数
    async getSelectShootingByGroupName() {
      this.data1 = [];
      this.playerData1 = [];
      this.playerData2 = [];
      this.groupNameData.groupName = this.groupData.groupNameList[0];
      const res = await getSelectShootingByGroupName(this.groupNameData);
      // console.log(res);
      if (res.status === 200) {
        if (res.data !== null) {
          this.data1 = res.data.targetList;
          this.playerData1 = res.data.championList1.shootingScoreList;
          this.playerData2 = res.data.championList2.shootingScoreList;
          this.player1 = res.data.championList1.player;
          this.player2 = res.data.championList2.player;
          if (this.data1 && this.data1.length > 0) {
            this.updateData.status = this.data1[0].status + 1;
          }
          this.$forceUpdate();
        }
        if (this.currentIndex !== -1) {
          this.$refs.scorChild.getSelectShootingScore();
        }
        if (this.groupData.groupNameList && this.groupData.groupNameList.length === 2) {
          this.groupNameData.groupName = this.groupData.groupNameList[1];
          const res1 = await getSelectShootingByGroupName(this.groupNameData);
          if (res1.status === 200) {
            this.data1 = [...res.data.targetList, ...res1.data.targetList];
            this.$forceUpdate();
          }
        }
      }
      // console.log(this.playerData1);
      // console.log(this.playerData2);
    },
    // 一键开始
    async updateBtn() {
      // console.log(this.updateData);
      this.updateData.groupName = this.groupData.groupNameList[0];
      this.updateData.groupNumber = parseInt(this.updateData.groupName.slice(1, 2));
      this.updateData.status = this.updateData.status > 4 ? 4 : this.updateData.status;
      const res = await updateAllGroupStatus(this.updateData);
      if (this.groupData.groupNameList && this.groupData.groupNameList.length === 2) {
        this.updateData.groupName = this.groupData.groupNameList[1];
        this.updateData.groupNumber = parseInt(this.updateData.groupName.slice(1, 2));
        await updateAllGroupStatus(this.updateData);
      }
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          type: 'success',
          message: '一键开始成功！'
        });
        this.getSelectShootingByGroupName();
        const res1 = await getSelectShootingByscheduleId({ scheduleId: this.scheduleId });
        // console.log(res);
        if (res1.status === 200) {
          this.groupData = res1.data;
        }
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },

    // -----------------------------------
    // 改状态
    async getSelect() {
      // console.log('111');
      this.currentIndex = -1;
    },
    // 编辑
    editClick: function (itemMethods, index) {
      this[itemMethods.status](itemMethods);
      // console.log(index);
      this.currentIndex = index;
    },
    1: function () {
      console.log('点击');
    },
    2: function (val) {
      // console.log(val);
      this.$refs.prepareChild.openEdit(val)
    },
    3: function (val) {
      this.$refs.tryChild.openEdit(val)
    },
    4: function (val) {
      this.$refs.scorChild.openEdit(val)
    },

    // ===========================
    // 投屏
    toupingBtn() {
      window.open(urls.largeScreenUrl + '/#/championships?scheduleId=' + this.updateData.scheduleId + '&token=' + sessionStorage.getItem('usertoken'),
        'championships',
        'resizable=1, scrollbars=1, fullscreen=0, height=1080, width=1920, screenX=0 , left=1920, toolbar=0, menubar=0, status=1');
    }
  }
}
</script>

<style lang="scss" scoped>
.championshipDetails {
  .topBox {
    width: 100%;
    height: 22px;
    display: flex;
    .nameBox {
      height: 22px;
      margin-right: 80px;
    }
    .typeBox {
      height: 22px;
      margin-right: 80px;
    }
    .timeBox {
      height: 22px;
      margin-right: 70px;
    }
    .span1Box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
    }
    .span2Box {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      .ydBox {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        margin-right: 5px;
      }
      .ydBox1 {
        background: #aaa;
      }
      .ydBox2 {
        background: #0e8ddf;
      }
      .ydBox3 {
        background: #00b050;
      }
      .ydBox4 {
        background: #00b050;
      }
      .nameBox {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .nameBox2 {
        color: #0e8ddf;
      }
      .nameBox3 {
        color: #00b050;
      }
      .nameBox4 {
        color: #00b050;
      }
    }
  }
  .midBox {
    margin-top: 26px;
    .bwxxBox {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 20px;
    }
    .flBox {
      margin-top: 10px;
      height: 30px;
      line-height: 30px;
      display: flex;
      div {
        .ydBox {
          display: inline-block;
          width: 14px;
          height: 14px;
          border-radius: 7px;
          margin-right: 5px;
        }
        .ydBox0 {
          background: #aaa;
        }
        .ydBox1 {
          background: #00b050;
        }
        .ydBox2 {
          background: #f77120;
        }
        .ydBox3 {
          background: #0e8ddf;
        }
        .nameBox {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-right: 77px;
        }
        .nameBox0 {
          color: #aaa;
        }
        .nameBox1 {
          color: #00b050;
        }
        .nameBox2 {
          color: #f77120;
        }
        .nameBox3 {
          color: #0e8ddf;
          margin-right: 40px;
        }
      }
      button {
        cursor: pointer;
        width: 95px;
        height: 30px;
        line-height: 30px;
        background: #06206b;
        border-radius: 2px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #eaefff;
        padding: 0;
        border: none;
      }
      .timeBox {
        margin-left: 10px;
        .jsBox {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #7d7d7d;
        }
        .tiBox {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e74a4a;
        }
      }
    }
    .playersBox {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      margin-top: 25px;
      .playBox {
        margin-right: 30px;
        margin-bottom: 20px;
        .playerBox {
          position: relative;
          cursor: pointer;
          width: 80px;
          height: 80px;
          line-height: 80px;
          text-align: center;
          background: #ffffff;
          box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.23);
          border-radius: 8px;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 80px;
            height: 27px;
          }
        }
        .numberBox {
          width: 80px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          margin-top: 5px;
        }
        .LvOne {
          color: #00b050;
        }
        .LvTwo {
          color: #f77120;
        }
        .LvThree {
          color: #0e8ddf;
        }
        .LvOne1 {
          color: #00b050;
        }
        .LvTwo2 {
          color: #f77120;
        }
        .LvThree3 {
          color: #0e8ddf;
        }
      }
      .active {
        .LvOne {
          color: #fff;
          background-color: #00b050;
        }
        .LvTwo {
          color: #fff;
          background-color: #f77120;
        }
        .LvThree {
          color: #fff;
          background-color: #0e8ddf;
        }
      }
    }
    .playersBox1 {
      text-align: center;
      color: #aaa;
    }
  }
  .botBox {
    margin-top: 26px;
    .titleBox {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 20px;
    }
    .tableBox {
      margin-top: 10px;
      .topBox {
        height: 32px;
        background: #dddddd;
        display: flex;
        div {
          flex: 1;
          height: 32px;
          line-height: 32px;
        }
        .xsBox {
          display: flex;
          padding: 0 20px;
          box-sizing: border-box;
          div {
            flex: 1;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: #7d7d7d;
          }
        }
      }
      .contentBox {
        display: flex;
        div {
          flex: 1;
        }
        .leftBox {
          .titBox {
            display: flex;
            height: 36px;
            border-bottom: 1px solid #dddddd;
            div {
              flex: 1;
              height: 36px;
              line-height: 36px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #7d7d7d;
            }
          }
          .contBox {
            height: 36px;
            line-height: 36px;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #7d7d7d;
            border-bottom: 1px solid #dddddd;
          }
          .contBox1 {
            display: flex;
            div {
              flex: 1;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #7d7d7d;
            }
          }
        }
        .midBox1 {
          height: 359px;
          display: flex;
          .sumBox {
            flex: none;
            width: calc(50% - 40px);
            height: 359px;
            line-height: 359px;
            text-align: center;
            font-size: 120px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #06206b;
          }
          .vsBox {
            flex: none;
            width: 80px;
            margin-top: 28px;
            .xBox {
              width: 1px;
              height: 141px;
              border: none;
              background-color: #dddddd;
              margin-left: 40px;
            }
            .vssBox {
              width: 43px;
              height: 43px;
              line-height: 43px;
              text-align: center;
              font-size: 30px;
              font-family: PangMenZhengDao;
              color: #e74a4a;
              margin-left: 21px;
            }
          }
        }
      }
    }
  }
}
</style>


