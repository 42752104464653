import axios from 'axios';
// import router from '@/router/router';
import { Notification, MessageBox } from 'element-ui';
// import { getToken } from '@/utils/auth';
// 引入路由对象
import router from "../router"

// 创建axios实例
// const baseURL = 'http://203.124.15.236:8666';
const baseURL = 'https://shooting.zhihanyixing.com/api';
// const baseURL = 'http://shooting.zhihanyixing.com:8666/api';
// const baseURL = 'http://192.168.0.115:8666/api';
const service = axios.create({
  baseURL,
  timeout: 20000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
  config => {
    if (sessionStorage.getItem('usertoken')) {
      config.headers['token'] = sessionStorage.getItem('usertoken'); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json';
    // config.headers['Content-Type'] = 'multipart/form-data';
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
    // console.log(config);
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
  response => {
    // console.log(response);
    if (response.data.status === 400) {
      Notification.error('登录已过期，请重新登录', '警告', {
        confirmButtonText: '确定',
        callback: action => {
          router.push('/login')
        }
      });
      return router.push('/login')
    }
    const code = response.status;
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      });
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject('error');
    } else {
      // alert(JSON.stringify(response.data))
      return response.data;
    }
  },
  error => {
    // console.log(error);
    let code = 0;
    try {
      code = error.response.data.status;
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        Notification.error({
          title: '网络请求超时',
          duration: 10000
        });
        return Promise.reject(error);
      }
    }
    if (code) {
      // console.log(code);
      if (code === 401) {
        MessageBox.confirm(
          '登录状态已过期，您可以继续留在该页面，或者重新登录',
          '系统提示',
          {
            confirmButtonText: '重新登录',
            cancelButtonText: '取消',
            type: 'warning'
          }
        );
      } else if (code === 403) {
        router.push({ path: '/401' });
      } else {
        const errorMsg = error.response.data.message;
        if (errorMsg !== undefined) {
          Notification.error({
            title: errorMsg,
            duration: 5000
          });
        }
      }
    } else {
      Notification.error({
        title: '接口请求失败',
        duration: 5000
      });
    }
    return Promise.reject(error);
  }
);
export default service;

