<template>
  <div class="jurisdiction">
    <!-- 权限设置 -->
    <el-dialog
      title="权限设置"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="false"
      append-to-body
    >
      <div>
        <el-tree
          :data="data"
          show-checkbox
          node-key="id"
          :default-checked-keys="menuList"
          :props="defaultProps"
          @check="handleCheck"
          v-if="show"
        >
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button type="primary" @click="okBtn" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectMenu, getIdMenu } from '@/api/systemManagement/roleManagement/index.js'
export default {
  name: 'Jurisdiction',
  data() {
    return {
      show: false,
      id: null,//当前人员ID
      dialogVisible: false,
      data: [],
      defaultProps: {
        children: 'children',
        label: 'title'
      },
      menuList: [],
      selectList: []
    }
  },
  created() {
    this.getSelectMenu();
    // this.getIdNowMenu()
  },
  methods: {
    // 获取菜单列表
    async getSelectMenu() {
      // console.log('menu');
      const res = await getSelectMenu();
      // console.log(res, 3);
      if (res.status === 200) {
        this.data = res.data;
      }
    },
    // 获取当前角色已有的菜单
    async getIdNowMenu(val) {
      console.log('alreadymenu');
      const res = await getIdMenu({ id: val })
      console.log('alreadymenu1');
      let data = []
      if (res.status === 200) {
        console.log('alreadymenu2');

        res.data.authList.forEach((item) => {
          console.log('alreadymenu3');
          data.push(item.id)
          // this.menuList.push(item.id)
        })
      }
      this.menuList = data
      this.selectList = data
      console.log('3', this.menuList);
    },
    // 打开弹层
    openJurisdiction(val1, val2) {
      this.$nextTick()
      console.log(val1, val2);
      this.show = val2
      this.menuList = []
      console.log('a', this.menuList);
      console.log('b', this.selectList);
      this.dialogVisible = true;
      this.$forceUpdate()
      this.getIdNowMenu(val1)

    },
    handleCheck(data, checkedData) {
      // 共两个参数，依次为：
      // 传递给 data 属性的数组中该节点所对应的对象、
      // 树目前的选中状态对象，
      // 包含 checkedNodes、checkedKeys、halfCheckedNodes、halfCheckedKeys 四个属性
      // console.log(checkedData);
      // 这是选中的节点的key数组
      // console.log(checkedData.checkedKeys);
      // 这是选中的节点数组
      // console.log(checkedData.checkedNodes);
      this.selectList = checkedData.checkedKeys;
      console.log('yixuan', this.menuList);
      console.log('xiugai', this.selectList);
    },
    // 
    okBtn() {
      this.$emit('changeEdit', this.selectList)
      this.selectList = this.menuList
      // this.$nextTick()
      // console.log(this.selectList);
      this.dialogVisible = false;
      this.show = false;
      console.log(this.menuList, this.selectList);

      this.id = null;
      this.menuList = []
      this.selectList = []

      // this.handleCheck(data,)
    },
    cancel() {
      // this.$nextTick()
      this.dialogVisible = false;
      this.show = false;
      this.id = null;
      this.menuList = []
      this.selectList = []
      console.log(this.menuList, this.selectList);
      // this.handleCheck()

    }
  }

}
</script>

<style lang="scss" scoped>
</style>