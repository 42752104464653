<template>
  <div class="allPlayersBox">
    <div class="playerText">全部选手</div>
    <div class="searchBtn">
      <el-input
        placeholder="请输入姓名"
        v-model="paging.data.playerName"
        clearable
      >
      </el-input>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="add">新增</el-button>
      <el-button type="primary" size="small" @click="userImport"
        >导入</el-button
      >
      <el-button type="primary" size="small" @click="deletePlayer"
        >删除</el-button
      >
    </div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        size="small"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="60"> </el-table-column>
        <el-table-column label="选手编号" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.number }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="nationalCode" label="全国编码" width="150">
        </el-table-column>
        <el-table-column prop="name" label="选手姓名" width="80">
        </el-table-column>
        <el-table-column label="性别" width="60">
          <template slot-scope="scope">
            <span v-if="scope.row.sex === true">男</span>
            <span v-else-if="scope.row.sex === false">女</span>
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="60"></el-table-column>
        <el-table-column
          prop="idCard"
          label="身份证号"
          width="160"
        ></el-table-column>
        <el-table-column prop="telephone" label="联系方式" width="120">
        </el-table-column>
        <el-table-column prop="city" label="城市" width="80"> </el-table-column>
        <el-table-column prop="company" label="单位"> </el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row.id)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deletePlayer(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="paging.page"
        :page-size="paging.size"
        layout="total, prev, pager, next, jumper"
        :total="paging.total"
      >
      </el-pagination>
    </div>
    <ImportPlayer
      ref="importChild"
      @getSelectPlayer="getSelectPlayer"
    ></ImportPlayer>
  </div>
</template>

<script>
import { getSelectPlayer, deletePlayer } from '@/api/playerManagement/index.js'
import ImportPlayer from './ImportPlayer.vue'
export default {
  name: 'allPlayersBox',
  components: { ImportPlayer, },
  props: {},
  data() {
    return {
      paging: {
        data: {
          level: null,
          playerName: "",
          scheduleId: "",
          name: ""
        },
        page: 1,
        size: 10,
        total: 0
      },
      competitionId: null,
      loading: true,
      tableData: [], // 选手列表
      deleteplayerIdList: [], // 删除选手id列表
      options1: [],
      options2: [],
    }
  },
  created() {
    this.getSelectPlayer();
  },
  methods: {
    // 获取用户列表
    async getSelectPlayer() {
      this.loading = true;
      const res = await getSelectPlayer(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectPlayer()
    },
    // 点击搜索
    async search() {
      // this.paging.data.competitionType = this.type;
      this.paging.page = 1;
      // console.log(this.paging);
      this.getSelectPlayer();
    },
    // 新增
    add() {
      // console.log(111)
      this.$router.push('/home/playerInformation/addPlayer');
    },
    // 编辑
    edit(val) {
      // console.log(val);
      this.$router.push('/home/playerInformation/editPlayer');
      sessionStorage.setItem('playerId', val);
    },
    // 获取选择的用户id
    handleSelectionChange(val) {
      // console.log(val);
      this.deleteplayerIdList = [];
      if (val.length > 0) {
        val.forEach(item => {
          this.deleteplayerIdList.push(item.id);
        })
      };
      // console.log(this.deleteplayerIdList)
    },
    // 删除用户
    deletePlayer(val) {
      // console.log(typeof (val));
      // console.log(val);
      if (typeof (val) === 'number') {
        this.deleteplayerIdList = [val];
      }
      if (this.deleteplayerIdList.length > 0) {
        this.$confirm('确认删除所选选手?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          // console.log(this.deleteplayerIdList);
          const res = await deletePlayer(this.deleteplayerIdList);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            });
            this.getSelectPlayer();
          } else {
            this.$message({
              showClose: true,
              type: 'error',
              message: '删除失败!' + res.msg
            });
          }
        }).catch(() => {
          this.$message({
            showClose: true,
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要删除的用户！'
        });
      }
    },
    // 点击导入
    userImport() {
      this.$refs.importChild.openImport();
    },
  }
}
</script>

<style lang="scss" scoped>
.allPlayersBox {
  border: 1px solid #ccc;
  padding: 15px;
  box-sizing: border-box;
  .playerText {
    color: #666;
    margin-bottom: 15px;
  }
}

.searchBtn {
  ::v-deep.el-cascader {
    .el-input {
      width: 100%;
    }
  }
  .btnBox1 {
    margin: 0 !important;
  }
}

::v-deep .tableContent {
  margin-top: 20px;

  // .el-table--border {
  //   border-bottom: 1px solid #ebeef5;
  // }
  td.el-table_1_column_9 {
    .cell {
      padding: 0 !important;
    }
  }
  td.el-table_1_column_10 {
    .cell {
      padding: 0 !important;
    }
  }
  td.el-table_1_column_11 {
    .cell {
      padding: 0 !important;
    }
  }
  ::v-deep.el-table__body {
    .el-table__cell {
      padding: 0 !important;
    }
  }
}

.block {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
