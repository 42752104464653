<template>
  <div class="raceSchedule">
    <!--赛程管理 -->
    <div v-if="this.$route.path === '/home/raceSchedule'">
      <div class="searchBtn">
        <el-input
          placeholder="请输入比赛名称"
          v-model="paging.data.name"
          clearable
        >
        </el-input>
        <!-- <el-cascader
          ref="refSubCat"
          v-model="typeVal"
          :options="field105Options"
          :style="{ width: '180px' }"
          placeholder="请选择比赛类型"
          @change="handleChange"
          clearable
        ></el-cascader> -->
        <el-select v-model="paging.data.type" placeholder="请选择比赛类型">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <!-- <div class="btn"> -->
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button type="primary" size="small" @click="add">新增</el-button>
        <!-- </div> -->
      </div>
      <div class="tableContent">
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          size="small"
        >
          <!-- <el-table-column type="selection"> </el-table-column> -->
          <el-table-column label="比赛编号" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.number }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="比赛名称（项目）">
          </el-table-column>
          <el-table-column prop="type" label="比赛类型" width="140">
          </el-table-column>
          <el-table-column label="赛程阶段" width="120">
            <template slot-scope="scope">
              <div v-if="scope.row.scheduleList.length > 0">
                <div
                  :class="index === 0 ? 'litDelBox litDelBox1' : 'litDelBox'"
                  v-for="(item, index) in scope.row.scheduleList"
                  :key="index"
                >
                  <span v-if="item.scheduleName === 1">淘汰赛</span>
                  <span v-if="item.scheduleName === 2">资格赛</span>
                  <span v-if="item.scheduleName === 3">决赛</span>
                  <span v-if="item.scheduleName === 6">夺冠赛</span>
                </div>
              </div>
              <div v-else style="margin: 0 8px; color: #ccc">暂未设置</div>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.scheduleList.length > 0">
                <div
                  :class="index === 0 ? 'litDelBox litDelBox1' : 'litDelBox'"
                  v-for="(item, index) in scope.row.scheduleList"
                  :key="index"
                >
                  {{ item.startTime }}
                </div>
              </div>
              <div v-else style="margin: 0 8px; color: #ccc">暂未设置</div>
            </template>
          </el-table-column>
          <el-table-column label="结束时间" width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.scheduleList.length > 0">
                <div
                  :class="index === 0 ? 'litDelBox litDelBox1' : 'litDelBox'"
                  v-for="(item, index) in scope.row.scheduleList"
                  :key="index"
                >
                  {{ item.endTime }}
                </div>
              </div>
              <div v-else style="margin: 0 8px; color: #ccc">暂未设置</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="updateRace(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="paging.page"
          :page-size="paging.size"
          layout="total, prev, pager, next, jumper"
          :total="paging.total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="this.$route.path === '/home/raceSchedule/addRace'">
      <AddUserVue></AddUserVue>
    </div>
    <div v-else>
      <EditUserVue></EditUserVue>
    </div>
  </div>
</template>

<script>
import { getSelectSchedule, deleteSchedule } from '@/api/eventManagement/raceSchedule/index.js'
import AddUserVue from './addRace.vue'
import EditUserVue from './editRace.vue'
export default {
  name: 'RaceSchedule',
  components: {
    AddUserVue, EditUserVue
  },
  created() {
    this.getSelectSchedule();
  },
  data() {
    return {
      paging: {
        data: {
          name: "",
          type: ""
        },
        page: 1,
        size: 13,
        total: 0
      },
      loading: true,
      typeVal: [],
      type: '',
      // field105Options: [
      //   {
      //     "label": "男子",
      //     "value": 1,
      //     "id": 103,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 11,
      //       "id": 105,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 12,
      //       "id": 106,
      //     }]
      //   }, {
      //     "label": "女子",
      //     "value": 2,
      //     "id": 104,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 21,
      //       "id": 113,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 22,
      //       "id": 114,
      //     }]
      //   }, {
      //     "label": "不限",
      //     "value": 3,
      //     "id": 105,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 31,
      //       "id": 115,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 32,
      //       "id": 116,
      //     }]
      //   }
      // ], // 比赛类型：男子（女子）10米步枪（手枪）资格赛、决赛（夺冠赛
      tableData: [], // 赛程列表
      options: [
        {
          id: 1,
          label: '男子10米气步枪'
        },
        {
          id: 2,
          label: '男子10米气手枪'
        },
        {
          id: 3,
          label: '女子10米气步枪'
        },
        {
          id: 4,
          label: '女子10米气手枪'
        },
        {
          id: 5,
          label: '不限10米气步枪'
        },
        {
          id: 6,
          label: '不限10米气手枪'
        },
      ]
    }
  },
  methods: {
    // 获取赛程列表
    async getSelectSchedule() {
      this.loading = true;
      const res = await getSelectSchedule(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        this.tableData.forEach(item => {
          for (let i = 0; i < item.scheduleList.length; i++) {
            if (item.scheduleList[i].scheduleName === 4 || item.scheduleList[i].scheduleName === 5) {
              item.scheduleList.splice(i, 1);
              i--;
            }
          }
        });
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectSchedule()
    },
    // 点击搜索
    async search() {
      // this.paging.data.type = this.type;
      this.paging.page = 1;
      this.getSelectSchedule();
    },
    // 级联选择器change事件
    // handleChange(value) {
    //   // console.log(value);
    //   this.field105Options.forEach(item => {
    //     if (value.length > 0) {
    //       if (value[0] === item.value) {
    //         let str1 = item.label
    //         if (item.children) {
    //           item.children.forEach(item1 => {
    //             if (value[1] === item1.value) {
    //               let str2 = item1.label
    //               this.type = str1 + str2;
    //             }
    //           })
    //         } else {
    //           this.type = str1;
    //         }
    //       }
    //     } else {
    //       this.type = ''
    //     }
    //   })
    // },
    // 新增
    add() {
      // console.log(111)
      this.$router.push('/home/raceSchedule/addRace');
    },
    // 编辑
    edit(val) {
      this.$router.push('/home/raceSchedule/editRace');
      sessionStorage.setItem('raceId', val);
    },
    // 删除赛程
    updateRace(val) {
      this.$confirm('确认删除该赛程?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log(val);
        const res = await deleteSchedule({ competitionId: val });
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSelectSchedule();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.raceSchedule {
  padding: 20px;

  .searchBtn {
    ::v-deep.el-cascader {
      .el-input {
        width: 100%;
      }
    }
    .btnBox1 {
      margin: 0 !important;
    }
  }
  ::v-deep td.el-table_1_column_6 {
    .cell {
      padding: 0 !important;
    }
  }
  ::v-deep td.el-table_1_column_4 {
    .cell {
      padding: 0 !important;
    }
  }
  ::v-deep td.el-table_1_column_5 {
    .cell {
      padding: 0 !important;
    }
  }
  .litDelBox {
    display: flex;
    // justify-content: center;
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: none;
    padding-left: 10px;
    text-align: left;
    border-top: 1px solid #e6ebff;
    // border-right: none;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .el-select--mini {
      width: 100%;
      height: 100%;
      .el-input--mini {
        height: 100%;
        .el-input__inner {
          height: 60%;
          line-height: 100%;
          border: none;
        }
      }
    }
  }
  .litDelBox1 {
    border-top: none;
  }

  .tableContent {
    margin-top: 20px;

    // ::v-deep.el-table .el-table__body .el-table__cell {
    //     height: 32px !important;
    //     padding: 0px !important;
    //     line-height: 32px !important;
    // }

    // ::v-deep.el-table th.el-table__cell {
    //     height: 35px !important;
    //     padding: 0px !important;
    //     line-height: 35px !important;
    // }

    .el-table--border {
      border-bottom: 1px solid #ebeef5;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>

