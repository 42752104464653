<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    }
  },

}
</script>

<style lang="scss">
body {
  height: 100%;
  width: 100%;
  background-color: #e5f4fe;
  padding: 0;
  margin: 0;

  // // 取消数字输入框后的上下减
  // ::v-deep input::-webkit-outer-spin-button,
  // ::v-deep input::-webkit-inner-spin-button {
  //   -webkit-appearance: none !important;
  // }

  // ::v-deep input[type='number'] {
  //   -moz-appearance: textfield !important;
  // }
  // 设置模态框的样式

  .el-dialog__body {
    // padding: 0 !important;
    padding: 0 30px !important;
  }

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;
  }
  // .el-button--primary.is-active,
  // .el-button--primary:active {
  //   background: #5b87a3;
  //   border-color: #5b87a3;
  //   color: #fff;
  // }
  // 修改按钮激活后样式
  .el-button--primary:focus {
    background: #06206b;
    border-color: #06206b;
    color: #fff;
  }
  .el-button--primary:hover {
    background: #5b87a3;
    border-color: #5b87a3;
    color: #fff;
  }
  // /* // 修改滚动条样式 */
  // ::-webkit-scrollbar {
  //   width: 10px;
  //   /*高宽分别对应横竖滚动条的尺寸 */
  //   max-height: 10px;
  // }

  // /* 滚动槽的轨道 */
  // ::-webkit-scrollbar-track {
  //   border-radius: 30px;
  // }

  // /* 滚动条的小滑块 */
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 3px;
  //   background: #5B87A3;
  //   /* background: linear-gradient(180deg, #1160cf 0%, #cd06bf 100%); */
  // }
}

// 设置按钮区域的样式
.searchBtn {
  display: flex;
  flex-wrap: wrap;
  .el-button {
    margin-right: 20px !important;
    background: #06206b;
  }

  .el-input,
  .el-select,
  .el-cascader,
  .el-date-picker {
    width: 180px;
    margin-right: 30px;
    // margin-bottom: 20px;
  }
}
</style>