import request from '@/utils/request';

// 查看设备列表分页
export function getSelectDevice(data) {
  return request({
    url: '/device-management/selectDevice',
    method: 'post',
    data
  });
}

// 新增设备
export function addDevice(data) {
  return request({
    url: '/device-management/addDevice',
    method: 'post',
    data
  });
}

// 编辑设备
export function updateDevice(data) {
  return request({
    url: '/device-management/updateDevice',
    method: 'post',
    data
  });
}

// 根据设备id删除设备
export function deleteDevice(params) {
  return request({
    url: '/device-management/deleteDevice',
    method: 'get',
    params
  });
}