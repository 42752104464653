<template>
  <div class="roleList">
    <!-- 角色管理 -->
    <div class="btn">
      <el-button type="primary" size="small" @click="addRole">新增</el-button>
    </div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        size="small"
        border
      >
        <el-table-column prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column prop="userCount" label="角色人员数"> </el-table-column>
        <el-table-column prop="" label="操作" width="300">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editRole(scope.row)"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="deleteRole(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <AddRoleVue ref="add" @getSelectRole="getSelectRole"></AddRoleVue>
    <EditRoleVue ref="edit" @getSelectRole="getSelectRole"></EditRoleVue>
  </div>
</template>

<script>
import { getSelectRole, deletedRole } from '@/api/systemManagement/roleManagement/index.js'
import AddRoleVue from './AddRole.vue';
import EditRoleVue from './EditRole.vue';
export default {
  name: 'RoleList',
  components: {
    AddRoleVue,
    EditRoleVue
  },
  data() {
    return {
      tableData: [],
      loading: true
    }
  },
  created() {
    this.getSelectRole();
  },
  methods: {
    // 获取角色列表
    async getSelectRole() {
      this.loading = true;
      const res = await getSelectRole();
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data;
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 点击新增
    addRole() {
      this.$refs.add.openAdd();
    },
    // 删除角色
    deleteRole(val) {
      // console.log(val);
      this.$confirm('确认删除该角色?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deletedRole([val.roleId])
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '删除成功!'
          });
          this.getSelectRole();
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 编辑
    editRole(val) {
      // console.log('val', val);
      this.$refs.edit.openEdit(val);
    }
  }
}
</script>

<style lang="scss" scoped>
.roleList {
  padding: 20px;

  .tableContent {
    margin-top: 20px;

    ::v-deep.el-table .el-table__body .el-table__cell {
      height: 32px !important;
      padding: 0px !important;
      line-height: 32px !important;
    }
    ::v-deep.el-table th.el-table__cell {
      height: 35px !important;
      padding: 0px !important;
      line-height: 35px !important;
    }
  }
}
</style>