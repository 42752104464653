<template>
  <div class="import">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <div class="content">
        <div class="note">
          <p>注意事项：</p>
          <!-- <span
            >1. “比赛编号”为（赛前准备 > 比赛项目管理）模块的“比赛编号”</span
          > -->
          <span>1. 按照下载的模板表头填写相关信息。</span>
        </div>
        <div class="importContent">
          <p>导入流程：</p>
          <span
            >1.<el-button
              type="danger"
              size="mini"
              style="margin: 0 10px"
              @click="downloadUrl"
            >
              下载模板
            </el-button>
            按照注意事项填写相关信息
          </span>
          <br />
          <span style="display: flex; align-items: center"
            >2.
            <el-upload
              class="upload-demo"
              ref="upload"
              :limit="1"
              :action="fileurl"
              :on-change="upLoadChange"
              :on-success="successFile"
              :auto-upload="false"
              :show-file-list="false"
              :headers="{ token: token }"
            >
              <el-button
                style="margin: 0px 10px"
                slot="trigger"
                size="mini"
                type="danger"
                >选取文件
              </el-button>
            </el-upload>
            后再点击上传
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          size="medium"
          @click="submitUpload"
          :loading="loading"
          >上 传</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { download } from '@/api/playerManagement/index.js'
import urls from '@/utils/url.js'
export default {
  data() {
    return {
      dialogVisible: false,
      fileurl: urls.baseUrl + "/player-management/playerExcel",
      fileList: [],
      token: sessionStorage.getItem('usertoken'),
      loading: false,
      isFile: false
    };
  },
  methods: {
    // 下载模板
    async downloadUrl() {
      const res = await download();
      // console.log(res);
      if (res) {
        //创建一个隐藏的a链接
        const link = document.createElement("a");
        let blob = new Blob([res], { type: "application/vnd.ms-excel" });  //文件流处理
        link.style.display = "none";  //去除a标签的样式
        link.href = URL.createObjectURL(blob);
        link.download = '选手导入模板.xlsx';
        document.body.appendChild(link);
        //模拟点击事件
        link.click();
        //移除创建的a标签
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 打开当前模态框
    openImport() {
      this.isFile = false;
      this.dialogVisible = true;
    },
    submitUpload() {
      if (!this.isFile) {
        return this.$message({
          showClose: true,
          message: '请选择上传的文件！',
          type: 'warning'
        });
      }
      this.$refs.upload.submit();
      this.loading = true;
    },
    upLoadChange(file, fileList) {
      this.isFile = true;
      console.log(file);
      // if (file.response.code === 200) {
      //     this.$message({
      //         showClose: true,
      //         message: '导入成功！',
      //         type: 'success'
      //     });
      // } else {
      //     this.$message({
      //         showClose: true,
      //         message: '导入失败！' + file.response.msg ,
      //         type: 'error'
      //     });
      // }
      this.$message({
        showClose: true,
        message: '选取成功！',
        type: 'success'
      });
      console.log(fileList);
    },
    successFile(response, file, fileList) {
      // console.log('1', response);
      // console.log('2', file);
      // console.log('3', fileList);
      if (response.status === 200) {
        this.$message({
          showClose: true,
          message: '导入成功！',
          type: 'success'
        });
        this.isFile = false;
        this.loading = false;
        this.dialogVisible = false;
        this.$emit('getSelectPlayer');
      } else {
        this.$message({
          showClose: true,
          message: '导入失败！' + response.msg,
          type: 'error'
        });
        this.loading = false;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.import {
  .content {
    p {
      font-size: 16px;
      color: #303030;
      font-weight: 600;
      line-height: 20px;
    }

    span {
      display: inline-block;
      margin-top: 10px;
    }

    .importContent {
      margin-top: 20px;
      p {
        font-size: 16px;
        color: #303030;
        font-weight: 600;
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
  }
}
</style>