<template>
  <!-- 个人赛况详情---记分赛 -->
  <div class="scoringMatchBox">
    <el-dialog
      title="个人赛况详情"
      :visible.sync="dialogVisible"
      width="40%"
      :show-close="true"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="box1">
        <div>
          <span class="span1Box">姓名：</span>
          <span class="span2Box">{{ playerData.playerName }}</span>
        </div>
        <div>
          <span class="span1Box">单位：</span>
          <span class="span2Box">{{ playerData.company }}</span>
        </div>
      </div>
      <div class="box1">
        <div>
          <span class="span1Box">靶位编号：</span>
          <span class="span2Box">{{ playerData.targetName }}</span>
        </div>
        <div>
          <span class="span1Box">总环数：</span>
          <span class="span2Box">{{ playerData.allscore }}</span>
        </div>
      </div>
      <div class="box1">
        <div>
          <span class="span1Box">城市场地：</span>
          <span class="span2Box"
            >{{ playerData.siteCity }} — {{ playerData.siteName }}</span
          >
        </div>
        <div></div>
      </div>
      <div class="tableBox">
        <button
          v-if="scheduleStatus !== 3"
          type="text"
          class="addBtnBox"
          @click="editPersonal('add', playerData)"
        >
          新增
        </button>
        <el-table :data="tableData" max-height="320" style="width: 100%" border>
          // 空数据状态的插槽
          <template slot="empty">
            <div>选手比赛中</div>
          </template>
          <el-table-column prop="shootCount" label="发序"> </el-table-column>
          <el-table-column prop="ringValue" label="环值"> </el-table-column>
          <el-table-column label="方向">
            <template slot-scope="scope">
              <i v-if="scope.row.direction === 1" class="el-icon-top"></i>
              <i v-if="scope.row.direction === 2" class="el-icon-bottom"></i>
              <i v-if="scope.row.direction === 3" class="el-icon-back"></i>
              <i v-if="scope.row.direction === 4" class="el-icon-right"></i>
              <i v-if="scope.row.direction === 5" class="el-icon-top-left"></i>
              <i v-if="scope.row.direction === 6" class="el-icon-top-right"></i>
              <i
                v-if="scope.row.direction === 7"
                class="el-icon-bottom-right"
              ></i>
              <i
                v-if="scope.row.direction === 8"
                class="el-icon-bottom-left"
              ></i>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="zSum" label="总环数"> </el-table-column> -->
          <el-table-column prop="shootTime" label="时间"> </el-table-column>
          <el-table-column label="操作" v-if="scheduleStatus !== 3">
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="editPersonal('edit', scope.row)"
                >编辑</el-button
              >
              <el-button type="text" size="small" @click="delBtn(scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <button>试射</button> -->
    </el-dialog>
    <EditPersonal
      ref="edit"
      @getSelectShootingScore="getSelectShootingScore"
    ></EditPersonal>
  </div>
</template>

<script>
import EditPersonal from './editPersonal.vue';
import { getSelectShootingScore, deleteShootScore } from '@/api/realTime/index.js'
export default {
  name: 'scoringMatchBox',
  components: { EditPersonal },
  props: {
    currentIndex: Number
  },
  data() {
    return {
      dialogVisible: false,
      playerData: {},
      tableData: [],
      shootingScoreData: {
        playerId: null,
        scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
      },
      scheduleStatus: 1,
      deleteShootScoreData: {
        competitionId: parseInt(sessionStorage.getItem('competitionId')),
        playerId: null,
        scheduleId: parseInt(sessionStorage.getItem('scheduleId')),
        shootScoreId: null
      }
    }
  },
  computed: {},
  watch: {},
  created() { },
  methods: {
    // 编辑
    editPersonal(edit, val) {
      // console.log(val);
      this.$refs.edit.openEdit(edit, val);
    },
    // 根据选手id查询分数详情
    async getSelectShootingScore() {
      this.tableData = [];
      const res = await getSelectShootingScore(this.shootingScoreData);
      // console.log(res);
      // console.log('111');
      if (res.status === 200) {
        this.playerData = res.data.player;
        this.scheduleStatus = res.data.scheduleStatus;
        this.tableData = res.data.shootingScoreList;
      }
    },
    // 打开当前模态框
    openEdit(val) {
      // console.log(val);
      this.shootingScoreData.playerId = val.playerId;
      this.shootingScoreData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.deleteShootScoreData.playerId = val.playerId;
      this.deleteShootScoreData.scheduleId = parseInt(sessionStorage.getItem('scheduleId'));
      this.getSelectShootingScore();
      // console.log(this.currentIndex);
      this.dialogVisible = true;
    },
    // 删除
    async delBtn(val) {
      // console.log(val);
      this.$confirm('确认删除该条成绩?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.deleteShootScoreData.shootScoreId = val.id;
        // console.log(this.deleteShootScoreData);
        const res = await deleteShootScore(this.deleteShootScoreData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功！'
          });
          this.getSelectShootingScore();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 关闭弹层
    handleClose() {
      // console.log('111');
      this.$emit('getSelect');
      this.$emit('getSelectShootingByGroupName');
      this.dialogVisible = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.scoringMatchBox {
  position: relative;
  .box1 {
    display: flex;
    margin-top: 20px;
    div {
      flex: 1;
      .span1Box {
        display: inline-block;
        width: 105px;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
        text-align: right;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
      }
      .span2Box {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #7d7d7d;
      }
    }
  }
  .tableBox {
    position: relative;
    margin-top: 20px;
    padding-bottom: 60px;
    .addBtnBox {
      position: absolute;
      top: -45px;
      right: 0;
      // width: 60px;
      // height: 30px;
      cursor: pointer;
      padding: 5px 15px;
      background: #06206b;
      color: #fff;
      border: none;
      border-radius: 5px;
    }
    ::v-deep.gutter {
      width: 10px !important;
    }
    // 设置滚动条的宽度
    ::v-deep.el-table__body-wrapper::-webkit-scrollbar {
      width: 10px;
    }
    // 设置滚动条的背景色和圆角
    ::v-deep.el-table__body-wrapper::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 8px;
    }
  }
  // button {
  //   position: absolute;
  //   bottom: 50px;
  //   right: 50%;
  //   margin-right: -35px;
  //   width: 70px;
  //   height: 30px;
  //   line-height: 30px;
  //   background: #06206b;
  //   border-radius: 2px;
  //   border: none;
  //   font-size: 14px;
  //   font-family: PingFangSC-Semibold, PingFang SC;
  //   font-weight: 600;
  //   color: #eaefff;
  //   padding: 0;
  //   margin-top: 20px;
  // }
}
</style>


