import request from '@/utils/request';

// 查看比赛列表
export function getSelectCompetition(data) {
  return request({
    url: '/competition-management/selectCompetition',
    method: 'post',
    data
  });
}

// 查询场地下拉框
export function getSelectSiteDown() {
  return request({
    url: '/site-management/selectSiteDown',
    method: 'get'
  });
}

// 新增比赛
export function addCompetition(data) {
  return request({
    url: '/competition-management/addCompetition',
    method: 'post',
    data
  });
}

// 删除比赛
export function deleteCompetition(params) {
  return request({
    url: '/competition-management/deleteCompetition',
    method: 'get',
    params
  });
}

// 根据id查看比赛详情
export function getSelectCompetitionDetails(params) {
  return request({
    // url: '/competition-management/selectCompetitionDetails',
    url: '/competition-management/competition/details',
    method: 'get',
    params
  });
}

// 编辑比赛
export function updateCompetition(data) {
  return request({
    url: '/competition-management/updateCompetition',
    method: 'post',
    data
  });
}

// 新增比赛选手
export function addCompetitionPlayer(data) {
  return request({
    url: '/competition-management/addCompetitionPlayer',
    method: 'post',
    data
  });
}

// 查看所有选手
export function getSelectAllPlayer(params) {
  return request({
    url: '/player-management/selectAllPlayer',
    method: 'get',
    params
  });
}

// 通过比赛id查询分组列表
export function getSelectGroup(params) {
  return request({
    // url: '/group-management/selectGroupByComId',
    url: '/group-management/group/by/competition/site',
    method: 'get',
    params
  });
}

// 根据比赛id查看已选选手
export function getSelectCompetitionPlayer(params) {
  return request({
    // url: '/competition-management/selectCompetitionPlayer',
    url: '/competition-management/competition/player/by/relation',
    method: 'get',
    params
  });
}

// 根据比赛id查询总人数、分组数量、靶位数量
export function getSelectGroupCountByComtetionId(params) {
  return request({
    // url: '/group-management/selectGroupCountByComtetionId',
    url: '/group-management/site/number',
    method: 'get',
    params
  });
}

// 查询所有分组不分页
export function getSelectAllGroup(params) {
  return request({
    // url: '/group-management/selectAllGroup',
    url: '/group-management/reassign/list',
    method: 'get',
    params
  });
}

// 根据场地id查看所有靶位编号
export function getTargetSiteNumber(params) {
  return request({
    url: '/site/target/position/target/site/number',
    method: 'get',
    params
  });
}

// // 编辑分组
// export function updareGroup(data) {
//   return request({
//     url: '/group-management/updareGroup',
//     method: 'post',
//     data
//   });
// }

// 重新分组
export function reassign(data) {
  return request({
    url: '/group-management/reassign',
    method: 'put',
    data
  });
}

// 根据比赛id查看所有选手
export function getSelectAllPlayerByComId(params) {
  return request({
    // url: '/player-management/selectAllPlayerByComId',
    url: '/player-management/player/by/relation',
    method: 'get',
    params
  });
}

// 根据比赛查询场地列表
export function getCompetitionSite(id) {
  return request({
    url: '/competition-management/competition/site/' + id,
    method: 'get',
  });
}

// 根据比赛id靶位Id
export function getSelectTargetIdBycomId(params) {
  return request({
    url: '/group-management/selectTargetIdBycomId',
    method: 'get',
    params
  });
}