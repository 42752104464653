<template>
  <div class="addProjects">
    <div class="header">
      <div class="tablistbox">
        <a
          href="javascript:void(0);"
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index)"
          :key="index"
          v-for="(item, index) in tablist"
          >{{ item.name }}</a
        >
      </div>
      <IndividualCompetition :is="currentView"></IndividualCompetition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AddProjects',
  components: {
    IndividualCompetition: () => import('./personalDetails/IndividualCompetition.vue'),
    TeamCompetition: () => import('./teamDetails/teamCompetition.vue'),
  },
  data() {
    return {
      isactive: sessionStorage.getItem('isactive') ? sessionStorage.getItem('isactive') : 0,
      tablist: [
        { name: '个人赛' },
        // { name: '团体赛' }
      ],
      currentView: sessionStorage.getItem('currentView') ? sessionStorage.getItem('currentView') : 'IndividualCompetition'
    };
  },
  methods: {
    // --------------------------
    handelchange(index) {
      this.isactive = index;
      if (index === 0) {
        this.currentView = 'IndividualCompetition';
      } else if (index === 1) {
        this.currentView = 'TeamCompetition';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.addProjects {
  // margin: 0 20px;
  position: relative;
  margin-bottom: 20px;
  .tablistbox {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    a {
      color: #555;
      height: 55px;
      line-height: 55px;
      font-size: 14px;
      margin-right: 100px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }
    .active {
      position: relative;
      height: 55px;
      line-height: 55px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      border-bottom: 3px solid #06206b;
    }
  }
}
</style>
