import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import UserManagement from '@/components/systemManagement/userManagement/index.vue'
import RoleMangement from '@/components/systemManagement/roleManagement/index.vue'
import PlayerInformation from '@/components/playerManagement/index.vue'
import CompetitionProject from '@/components/eventManagement/competitionProject/index.vue'
import RaceSchedule from '@/components/eventManagement/raceSchedule/index.vue'
// import Grouping from '@/components/eventManagement/grouping/index.vue'
import TargetSite from '@/components/targetSite/index.vue'
// import noView from '@/views/404.vue'
import DeviceManagement from '@/components/deviceManagement/index.vue'
import RealTime from '@/components/realTime/index.vue'
import ScoreQuery from '@/components/scoreQuery/index.vue'
Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: '/login',
},
{
  path: '/login',
  name: 'Login',
  component: Login
},
// {
//   path: '/404',
//   name: '404',
//   component: noView
// },

// 淘汰赛
{
  path: '/eliminates',
  name: 'Eliminates',
  component: () => import('@/views/realTime-main/eliminates.vue')
},
// 资格赛
{
  path: '/qualificationHeat',
  name: 'QualificationHeat',
  meta: {
    token: sessionStorage.getItem('usertoken')
  },
  component: () => import('@/views/realTime-main/qualificationHeat.vue')
},
// 决赛
{
  path: '/finals',
  name: 'Finals',
  component: () => import('@/views/realTime-main/finals.vue')
},
// 总决赛
{
  path: '/championshipList',
  name: 'dapin',
  component: () => import('@/views/realTime-main/championshipList.vue')
},
// 总决赛
{
  path: '/championships',
  name: 'Championships',
  component: () => import('@/views/realTime-main/championships.vue')
},
{
  path: '/home',
  name: 'Home',
  component: () => import( /* webpackChunkName: "about" */ '../views/home/Home.vue'),
  children: [
    // 系统用户管理
    {
      path: 'userManagement',
      name: 'UserManagement',
      component: UserManagement,
      meta: {
        cache: true,
        title: "系统管理>用户管理",
        breadcrumbList: [{
          id: 1,
          name: '系统管理',
          path: '/home'
        },
        {
          id: 2,
          name: '用户管理',
          path: '/home/userManagement'
        }]
      }
    },
    // 系统角色管理
    {
      path: 'roleMangement',
      name: 'RoleMangement',
      component: RoleMangement,
      meta: {
        cache: true,
        title: "系统管理>角色管理",
        breadcrumbList: [{
          id: 1,
          name: '系统管理',
          path: '/home'
        },
        {
          id: 2,
          name: '角色管理',
          path: '/home/roleMangement'
        }]
      }
    },
    // 选手信息
    {
      path: 'playerInformation',
      name: 'PlayerInformation',
      component: PlayerInformation,
      children: [
        // 新增选手
        {
          path: 'addPlayer',
          name: 'AddPlayer',
          component: PlayerInformation,
          meta: {
            cache: true,
            title: "选手信息>新增选手",
            breadcrumbList: [{
              id: 1,
              name: '选手信息',
              path: '/home/playerInformation'
            },
            {
              id: 2,
              name: '新增选手',
              path: '/home/playerInformation/addPlayer'
            }]
          }
        },
        // 编辑选手
        {
          path: 'editPlayer',
          name: 'EditPlayer',
          component: PlayerInformation,
          meta: {
            cache: true,
            title: "选手信息>编辑选手",
            breadcrumbList: [{
              id: 1,
              name: '选手信息',
              path: '/home/playerInformation'
            },
            {
              id: 2,
              name: '编辑选手',
              path: '/home/playerInformation/editPlayer'
            }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "选手信息>选手信息管理",
        breadcrumbList: [{
          id: 1,
          name: '选手信息',
          path: '/home/playerInformation'
        }]
      }
    },
    // 比赛项目管理
    {
      path: 'competitionProject',
      name: 'CompetitionProject',
      component: CompetitionProject,
      children: [
        // 比赛项目管理新建项目
        {
          path: 'addProjects',
          name: 'AddProjects',
          component: CompetitionProject,
          meta: {
            cache: true,
            title: "赛前准备>新增项目",
            breadcrumbList: [
              {
                id: 1,
                name: '赛前准备',
                path: '/home/competitionProject'
              },
              {
                id: 2,
                name: '新增项目',
                path: '/home/competitionProject/addProjects'
              }]
          }
        },
        // 比赛项目管理编辑项目
        {
          path: 'editProject',
          name: 'EditProject',
          component: CompetitionProject,
          meta: {
            cache: true,
            title: "赛前准备>编辑项目",
            breadcrumbList: [
              {
                id: 1,
                name: '赛前准备',
                path: '/home/competitionProject'
              },
              {
                id: 2,
                name: '编辑项目',
                path: '/home/competitionProject/editProject'
              }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "赛前准备",
        breadcrumbList: [{
          id: 1,
          name: '赛前准备',
          path: '/home/competitionProject'
        }]
      }
    },
    // 赛程管理
    // {
    //   path: 'raceSchedule',
    //   name: 'RaceSchedule',
    //   component: RaceSchedule,
    //   children: [
    //     // 比赛项目管理新建赛程
    //     {
    //       path: 'addRace',
    //       name: 'AddRace',
    //       component: RaceSchedule,
    //       meta: {
    //         cache: true,
    //         title: "赛前准备>赛程管理>新增赛程",
    //         breadcrumbList: [{
    //           id: 1,
    //           name: '赛前准备',
    //           path: '/home'
    //         },
    //         {
    //           id: 2,
    //           name: '赛程管理',
    //           path: '/home/raceSchedule'
    //         },
    //         {
    //           id: 3,
    //           name: '新增赛程',
    //           path: '/home/raceSchedule/addRace'
    //         }]
    //       }
    //     },
    //     // 比赛项目管理编辑赛程
    //     {
    //       path: 'editRace',
    //       name: 'EditRace',
    //       component: RaceSchedule,
    //       meta: {
    //         cache: true,
    //         title: "赛前准备>赛程管理>编辑赛程",
    //         breadcrumbList: [{
    //           id: 1,
    //           name: '赛前准备',
    //           path: '/home'
    //         },
    //         {
    //           id: 2,
    //           name: '赛程管理',
    //           path: '/home/raceSchedule'
    //         },
    //         {
    //           id: 3,
    //           name: '编辑赛程',
    //           path: '/home/raceSchedule/editRace'
    //         }]
    //       }
    //     },
    //   ],
    //   meta: {
    //     cache: true,
    //     title: "赛前准备>赛程管理",
    //     breadcrumbList: [{
    //       id: 1,
    //       name: '赛前准备',
    //       path: '/home'
    //     },
    //     {
    //       id: 2,
    //       name: '赛程管理',
    //       path: '/home/raceSchedule'
    //     }]
    //   }
    // },
    // 分组管理
    // {
    //   path: 'grouping',
    //   name: 'Grouping',
    //   component: Grouping,
    //   meta: {
    //     cache: true,
    //     title: "赛前准备>赛程管理",
    //     breadcrumbList: [{
    //       id: 1,
    //       name: '赛前准备',
    //       path: '/home'
    //     },
    //     {
    //       id: 2,
    //       name: '赛程管理',
    //       path: '/home/grouping'
    //     }]
    //   }
    // },
    // 场地靶位管理
    {
      path: 'targetSite',
      name: 'TargetSite',
      component: TargetSite,
      children: [
        // 场地管理新建场地
        {
          path: 'addField',
          name: 'AddField',
          component: TargetSite,
          meta: {
            cache: true,
            title: "场地靶位管理>新建场地靶位",
            breadcrumbList: [
              {
                id: 1,
                name: '场地靶位管理',
                path: '/home/targetSite'
              },
              {
                id: 2,
                name: '新建场地靶位',
                path: '/home/targetSite/addField'
              }]
          }
        },
        // 场地管理新建场地
        {
          path: 'editField',
          name: 'EditField',
          component: TargetSite,
          meta: {
            cache: true,
            title: "场地靶位管理>编辑场地靶位",
            breadcrumbList: [
              {
                id: 1,
                name: '场地靶位管理',
                path: '/home/targetSite'
              },
              {
                id: 2,
                name: '编辑场地靶位',
                path: '/home/targetSite/editField'
              }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "场地靶位管理",
        breadcrumbList: [
          {
            id: 1,
            name: '场地靶位管理',
            path: '/home/targetSite'
          }]
      }
    },
    // 设备管理
    {
      path: 'deviceManagement',
      name: 'DeviceManagement',
      component: DeviceManagement,
      children: [
        // 场地管理新建场地
        {
          path: 'addDevice',
          name: 'AddDevice',
          component: DeviceManagement,
          meta: {
            cache: true,
            title: "设备管理>新建设备",
            breadcrumbList: [
              {
                id: 1,
                name: '设备管理',
                path: '/home/deviceManagement'
              },
              {
                id: 2,
                name: '新建设备',
                path: '/home/deviceManagement/addDevice'
              }]
          }
        },
        // 场地管理新建场地
        {
          path: 'editDevice',
          name: 'EditDevice',
          component: DeviceManagement,
          meta: {
            cache: true,
            title: "设备管理>编辑设备",
            breadcrumbList: [
              {
                id: 1,
                name: '设备管理',
                path: '/home/deviceManagement'
              },
              {
                id: 2,
                name: '编辑设备',
                path: '/home/deviceManagement/editDevice'
              }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "设备管理",
        breadcrumbList: [
          {
            id: 1,
            name: '设备管理',
            path: '/home/deviceManagement'
          }]
      }
    },
    // 实时赛况
    {
      path: 'realTime',
      name: 'RealTime',
      component: RealTime,
      children: [
        // 详情
        {
          path: 'detail',
          name: 'Detail',
          component: RealTime,
          meta: {
            cache: true,
            title: "实时赛况>详情",
            breadcrumbList: [{
              id: 1,
              name: '实时赛况',
              path: '/home/realTime'
            },
            {
              id: 2,
              name: '详情',
              path: '/home/realTime/detail'
            }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "实时赛况",
        breadcrumbList: [
          {
            id: 1,
            name: '实时赛况',
            path: '/home'
          },
          // {
          //   id: 2,
          //   name: '成绩查询',
          //   path: '/home/realTime'
          // }
        ]
      }
    },
    // 成绩查询
    {
      path: 'scoreQuery',
      name: 'ScoreQuery',
      component: ScoreQuery,
      children: [
        // 比赛详情
        {
          path: 'details',
          name: 'Details',
          component: ScoreQuery,
          children: [
            // 个人成绩详情
            {
              path: 'personalDetails',
              name: 'PersonalDetails',
              component: ScoreQuery,
              meta: {
                cache: true,
                title: "成绩查询>比赛详情>个人成绩详情",
                breadcrumbList: [{
                  id: 1,
                  name: '成绩查询',
                  path: '/home/scoreQuery'
                },
                {
                  id: 2,
                  name: '比赛详情',
                  path: '/home/scoreQuery/details'
                },
                {
                  id: 3,
                  name: '个人成绩详情',
                  path: '/home/scoreQuery/details/personalDetails'
                }]
              }
            },
          ],
          meta: {
            cache: true,
            title: "成绩查询>比赛详情",
            breadcrumbList: [{
              id: 1,
              name: '成绩查询',
              path: '/home/scoreQuery'
            },
            {
              id: 2,
              name: '比赛详情',
              path: '/home/scoreQuery/details'
            }]
          }
        },
      ],
      meta: {
        cache: true,
        title: "成绩查询",
        breadcrumbList: [
          {
            id: 1,
            name: '成绩查询',
            path: '/home'
          },
          // {
          //   id: 2,
          //   name: '成绩查询',
          //   path: '/home/scoreQuery'
          // }
        ]
      }
    },
  ]
}]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   const mytoken = sessionStorage.getItem('token')
//   if (!mytoken) {
//     if (to.path === '/login') {
//       return next('/login')
//     }

//   } else {
//     next()
//   }
//   // console.log(to);
//   // next()
// })

router.beforeEach((to, from, next) => {
  const mytoken = sessionStorage.getItem('usertoken')

  if (mytoken) {//toekn存在
    next()
  } else {
    if (to.path == '/login') {//token不存在，并且是login页面
      next();
    } else {//token不存在，不是login页面
      next('/login');
    }
  }
})

export default router