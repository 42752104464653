<template>
  <div class="edit">
    <el-dialog
      title="编辑设备"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item prop="type">
          <span>设备类型</span><br />
          <el-select
            v-model="ruleForm.type"
            placeholder="请选择设备类型"
            clearable
            value-key="value"
          >
            <el-option
              v-for="item in rolesList"
              :label="item.label"
              :value="item.value"
              :key="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="number">
          <span>设备编号</span>
          <br />
          <el-input
            placeholder="请输入设备编号"
            v-model.trim="ruleForm.number"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { updateDevice } from '@/api/deviceManagement/index.js'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        createTime: "",
        id: null,
        number: "",
        type: null
      }, // 用户信息
      rolesList: [
        {
          label: '气步枪',
          value: 1
        },
        {
          label: '气手枪',
          value: 2
        },
      ],
      rules: {
        number: [
          { required: true, message: '请输入设备编号', trigger: 'blur' },
          { max: 20, message: '长度在 20 个字符以内', trigger: 'change' }
        ],
        value: [
          { required: true, message: '请选择设备类型', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    // 打开当前模态框
    openEdit(val) {
      // console.log(val);
      this.ruleForm = {
        createTime: val.createTime,
        id: val.id,
        number: val.number,
        type: val.type
      };
      this.dialogVisible = true;
    },
    // 点击提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // alert('submit!');
          // console.log(this.ruleForm);
          const res = await updateDevice(this.ruleForm);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '修改成功！',
              type: 'success'
            });
            this.dialogVisible = false;
            this.$emit('getSelectDevice');
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }

}
</script>

<style lang="scss" scoped>
.edit {
  ::v-deep.el-dialog {
    height: 300px;
    overflow-y: auto;
    // position: relative;
  }

  ::v-deep input::-webkit-outer-spin-button,
  ::v-deep input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type="number"] {
    -moz-appearance: textfield !important;
  }
  .el-button--primary {
    margin-left: 30px;
  }

  // height: 80%;
  .el-input,
  .el-select,
  .el-cascader {
    width: 100%;
  }
}
</style>