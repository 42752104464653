<template>
  <!-- 打印成绩 -->
  <div class="achievement">
    <div id="printRoot">
      <div class="printMeBox">
        <div class="titleBox">{{ scoreData.name }}</div>
        <div class="nameBox">{{ scoreData.type }}</div>
        <div class="lineBox"></div>
        <div class="siteBox">
          {{ playerData.siteCity }} — {{ playerData.siteName }}
        </div>
        <div class="playerBox">
          <div class="leftBox">
            <span>姓名：</span>
            <span>{{ playerData.name }}</span>
          </div>
          <div>
            <span>单位：</span>
            <span>{{ playerData.company }}</span>
          </div>
          <div class="rightBox">
            <span>总环数：</span>
            <span>{{ data1.shootScore }}</span>
          </div>
        </div>
        <div class="achievementBox">
          <div class="tableBox">
            <div class="topBox">
              <div>发序</div>
              <div>环数</div>
              <div>方向</div>
              <div class="itemBox1">时间</div>
            </div>
            <div class="contentBox">
              <div
                class="rowBox"
                v-for="(item, index) in playerScoreList1"
                :key="index"
              >
                <div class="itemBox">{{ item.shootCount }}</div>
                <div class="itemBox">{{ item.ringValue }}</div>
                <div class="itemBox">
                  <i v-if="item.direction === 1" class="el-icon-top"></i>
                  <i v-if="item.direction === 2" class="el-icon-bottom"></i>
                  <i v-if="item.direction === 3" class="el-icon-back"></i>
                  <i v-if="item.direction === 4" class="el-icon-right"></i>
                  <i v-if="item.direction === 5" class="el-icon-top-left"></i>
                  <i v-if="item.direction === 6" class="el-icon-top-right"></i>
                  <i
                    v-if="item.direction === 7"
                    class="el-icon-bottom-right"
                  ></i>
                  <i
                    v-if="item.direction === 8"
                    class="el-icon-bottom-left"
                  ></i>
                </div>
                <div class="itemBox itemBox1">{{ item.shootTime }}</div>
              </div>
            </div>
          </div>
          <div class="tableBox midBox">
            <div class="topBox">
              <div>发序</div>
              <div>环数</div>
              <div>方向</div>
              <div class="itemBox1">时间</div>
            </div>
            <div class="contentBox">
              <div
                class="rowBox"
                v-for="(item, index) in playerScoreList2"
                :key="index"
              >
                <div class="itemBox">{{ item.shootCount }}</div>
                <div class="itemBox">{{ item.ringValue }}</div>
                <div class="itemBox">
                  <i v-if="item.direction === 1" class="el-icon-top"></i>
                  <i v-if="item.direction === 2" class="el-icon-bottom"></i>
                  <i v-if="item.direction === 3" class="el-icon-back"></i>
                  <i v-if="item.direction === 4" class="el-icon-right"></i>
                  <i v-if="item.direction === 5" class="el-icon-top-left"></i>
                  <i v-if="item.direction === 6" class="el-icon-top-right"></i>
                  <i
                    v-if="item.direction === 7"
                    class="el-icon-bottom-right"
                  ></i>
                  <i
                    v-if="item.direction === 8"
                    class="el-icon-bottom-left"
                  ></i>
                </div>
                <div class="itemBox itemBox1">{{ item.shootTime }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="tableBox">
            <div class="topBox">
              <div>发序</div>
              <div>环数</div>
              <div>方向</div>
            </div>
            <div class="contentBox">
              <div
                class="rowBox"
                v-for="(item, index) in playerScoreList3"
                :key="index"
              >
                <div class="itemBox">{{ item.shootCount }}</div>
                <div class="itemBox">{{ item.ringValue }}</div>
                <div class="itemBox">
              <i v-if="item.direction === 1" class="el-icon-top"></i>
              <i v-if="item.direction === 2" class="el-icon-bottom"></i>
              <i v-if="item.direction === 3" class="el-icon-back"></i>
              <i v-if="item.direction === 4" class="el-icon-right"></i>
              <i v-if="item.direction === 5" class="el-icon-top-left"></i>
              <i v-if="item.direction === 6" class="el-icon-top-right"></i>
              <i
                v-if="item.direction === 7"
                class="el-icon-bottom-right"
              ></i>
              <i
                v-if="item.direction === 8"
                class="el-icon-bottom-left"
              ></i>
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="timeBox">{{ scoreTime }}</div>
        <div class="bottomBox">
          {{ scoreData.sponsor }}&{{ scoreData.sponsors }}
        </div>
      </div>
    </div>
    <div class="btnBox">
      <button class="previewBox" @click="closeBtn">返回</button>
      <button class="printBox" v-print="{ id: 'printRoot' }">打印</button>
    </div>
  </div>
</template>

<script>
import { getSelectScoreByPlayerId } from '@/api/scoreQuery/index.js';
export default {
  name: 'Achievement',
  components: {},
  props: {
  },
  data() {
    return {
      scoreByPlayer: {
        playerId: parseInt(sessionStorage.getItem('playerId')),
        scheduleId: parseInt(sessionStorage.getItem('scheduleId'))
      },
      playerData: {}, // 选手信息
      scoreData: {}, // 比赛详情
      data1: {},
      scoreTime: '',
      playerScoreList: [], // 选手成绩信息
      playerScoreList1: [], // 选手成绩信息
      playerScoreList2: [], // 选手成绩信息
      // playerScoreList3: [], // 选手成绩信息
    }
  },
  computed: {},
  watch: {},
  created() {
    this.getSelectScoreByPlayerId();
  },
  methods: {
    // 根据选手id查询成绩详情
    async getSelectScoreByPlayerId() {
      const res = await getSelectScoreByPlayerId(this.scoreByPlayer);
      // console.log(res);
      if (res.status === 200) {
        this.data1 = res.data;
        this.playerData = res.data.playerManagement;
        this.scoreData = res.data.competitionManagement;
        this.playerScoreList = res.data.shootingScoreList;
        this.scoreTime = res.data.shootingScoreList[0].createTime.slice(0, 10);
        // console.log(this.scoreTime);
        // 时间格式
        this.playerScoreList.forEach(item => {
          // console.log(item.shootTime.length);
          if (item.shootTime.length > 10) {
            let timer = item.shootTime.replace(" ", ":").replace(/\:/g, "-").split("-");
            // console.log(timer);
            item.shootTime = timer[3] + ':' + timer[4] + ':' + timer[5];
          }
        });
        // console.log(this.playerData);
        // console.log(this.playerScoreList);
        if (this.playerScoreList.length > 1) {
          let sum = this.playerScoreList.length / 2;
          // console.log(Math.ceil(sum));
          this.playerScoreList1 = this.playerScoreList.slice(0, Math.ceil(sum));
          this.playerScoreList2 = this.playerScoreList.slice(Math.ceil(sum));
          // this.playerScoreList3 = this.playerScoreList.slice(Math.ceil(sum) * 2);
          // console.log(this.playerScoreList1);
          // console.log(this.playerScoreList2);
          // console.log(this.playerScoreList3);
        } else {
          this.playerScoreList1 = this.playerScoreList;
        }
      }
    },
    // 返回
    closeBtn() {
      this.$emit('isActionClick');
    }
  }
}
</script>

<style lang="scss" scoped>
.achievement {
  .printMeBox {
    position: relative;
    width: 595px;
    height: 842px;
    background-image: url("~@/assets/achievement.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    padding-top: 45px;
    .titleBox {
      width: 595px;
      height: 37px;
      font-size: 26px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #303030;
      line-height: 37px;
      text-align: center;
    }
    .nameBox {
      width: 595px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 22px;
      text-align: center;
      margin-top: 15px;
    }
    .lineBox {
      width: 501px;
      height: 1px;
      background-color: #d7d7d7;
      margin-top: 12px;
      margin-left: 50px;
    }
    .siteBox {
      width: 595px;
      height: 22px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 22px;
      text-align: center;
      margin-top: 15px;
    }
    .playerBox {
      width: 595px;
      height: 22px;
      margin-top: 15px;
      display: flex;
      padding: 0 70px;
      box-sizing: border-box;
      div {
        flex: 1;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        text-align: center;
      }
      .leftBox {
        text-align: left;
      }
      .rightBox {
        text-align: right;
      }
    }
    .achievementBox {
      width: 595px;
      height: 530px;
      padding: 0 39px 0 45px;
      margin-top: 20px;
      box-sizing: border-box;
      display: flex;
      .tableBox {
        width: 250px;
        .topBox {
          width: 250px;
          height: 30px;
          background: #c3d3d5;
          display: flex;
          div {
            flex: 1;
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            text-align: center;
          }
          .itemBox1 {
            flex: 2;
          }
        }
        .contentBox {
          .rowBox {
            display: flex;
            width: 250px;
            height: 25px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #c3d3d5;
            border-top: none;
            div {
              flex: 1;
              height: 25px;
              line-height: 25px;
              text-align: center;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #7d7d7d;
            }
            .itemBox1 {
              flex: 2;
            }
          }
        }
      }
      .midBox {
        margin: 0 10px;
      }
    }
    .timeBox {
      width: 120px;
      height: 20px;
      font-size: 14px;
      text-align: right;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #303030;
      line-height: 20px;
      margin-left: 432px;
    }
    .bottomBox {
      position: absolute;
      bottom: 12px;
      left: 50%;
      transform: translate(-50%, 0);
      min-width: 120px;
      height: 30px;
      font-size: 12px;
      text-align: center;
      background-color: #fff;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      line-height: 30px;
      padding: 0 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .btnBox {
    width: 100%;
    height: 30px;
    margin-top: 27px;
    margin-bottom: 100px;
    .previewBox {
      cursor: pointer;
      width: 87px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #06206b;
      background: #eaefff;
      border: 0;
      border-radius: 2px;
      margin-left: 234px;
      margin-right: 33px;
    }
    .printBox {
      cursor: pointer;
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #eaefff;
      background: #06206b;
      border: 0;
      border-radius: 2px;
    }
  }
}
@media print {
  body {
    // -webkit-print-color-adjust: exact;
    // -moz-print-color-adjust: exact;
    // -ms-print-color-adjust: exact;
    // print-color-adjust: exact;
    margin: 0 auto;
    #printRoot {
      width: 100%;
      height: 842px * 16.1;
      background-color: #fff;
      padding-top: 350px;
    }
    .printMeBox {
      position: relative;
      width: 595px * 15;
      height: 842px * 15;
      background-color: #fff;
      background-image: url("~@/assets/achievement.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      box-sizing: border-box;
      padding-top: 45px * 15;
      .titleBox {
        width: 595px * 15;
        height: 37px * 15;
        font-size: 26px * 15;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303030;
        line-height: 37px * 15;
        text-align: center;
      }
      .nameBox {
        width: 595px * 15;
        height: 22px * 15;
        font-size: 16px * 15;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 22px * 15;
        text-align: center;
        margin-top: 15px * 15;
      }
      .siteBox {
        width: 595px * 15;
        height: 22px * 15;
        font-size: 18px * 15;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 22px * 15;
        text-align: center;
        margin-top: 15px * 15;
      }
      .lineBox {
        width: 501px * 15;
        height: 1px * 15;
        background-color: #d7d7d7;
        margin-top: 12px * 15;
        margin-left: 50px * 15;
      }
      .playerBox {
        width: 595px * 15;
        height: 22px * 15;
        margin-top: 15px * 15;
        display: flex;
        padding: 0 (70px * 15);
        box-sizing: border-box;
        div {
          flex: 1;
          font-size: 16px * 15;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303030;
          text-align: center;
        }
        .leftBox {
          text-align: left;
        }
        .rightBox {
          text-align: right;
        }
      }
      .achievementBox {
        width: 595px * 15;
        height: 530px * 15;
        padding: 0 (39px * 15) 0 (45px * 15);
        margin-top: 20px * 15;
        box-sizing: border-box;
        display: flex;
        .tableBox {
          width: 250px * 15;
          .topBox {
            width: 250px * 15;
            height: 30px * 15;
            background: #c3d3d5;
            display: flex;
            div {
              flex: 1;
              height: 30px * 15;
              line-height: 30px * 15;
              font-size: 16px * 15;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303030;
              text-align: center;
            }
            .itemBox1 {
              flex: 2;
            }
          }
          .contentBox {
            .rowBox {
              display: flex;
              width: 250px * 15;
              height: 25px * 15;
              background: #ffffff;
              box-sizing: border-box;
              border-top: none;
              border-right: 10px solid #c3d3d5;
              border-left: 10px solid #c3d3d5;
              border-bottom: 10px solid #c3d3d5;
              margin-bottom: 12px;
              .itemBox {
                flex: 1;
                height: 25px * 15;
                line-height: 25px * 15;
                text-align: center;
                font-size: 14px * 15;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #7d7d7d;
              }
              .itemBox1 {
                flex: 2;
              }
            }
          }
        }
        .midBox {
          margin: 0 150px;
        }
      }
      .timeBox {
        width: 120px * 15;
        height: 20px * 15;
        font-size: 14px * 15;
        text-align: right;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px * 15;
        margin-left: 432px * 15;
      }
      .bottomBox {
        position: absolute;
        bottom: 10px * 15;
        left: 50%;
        transform: translate(-50%, 0);
        // width: 150px * 15;
        height: 30px * 15;
        font-size: 12px * 15;
        text-align: center;
        background-color: #fff;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #06206b;
        line-height: 30px * 15;
        padding: 0 5px * 15;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
@page {
  /* 打印A4大小 */
  size: auto A4 landscape;
  margin: 0 auto;
}
</style>
