import request from '@/utils/request';

// 查看赛程列表分页
export function getSelectSchedule(data) {
  return request({
    url: '/competition-schedule/selectSchedule',
    method: 'post',
    data
  });
}

// 根据比赛id删除赛程
export function deleteSchedule(params) {
  return request({
    url: '/competition-schedule/deleteSchedule',
    method: 'get',
    params
  });
}

// 查看比赛列表下拉框
export function getSelectCompetitionDrop() {
  return request({
    url: '/competition-management/selectCompetitionDrop',
    method: 'get'
  });
}

// 新增赛程
export function addSchedule(data) {
  return request({
    url: '/competition-schedule/addSchedule',
    method: 'post',
    data
  });
}

// 根据id查看赛程详情
export function getSelectScheduleDetails(params) {
  return request({
    url: '/competition-schedule/selectScheduleDetails',
    method: 'get',
    params
  });
}

// 编辑赛程
export function updateSchedule(data) {
  return request({
    url: '/competition-schedule/updateSchedule',
    method: 'post',
    data
  });
}