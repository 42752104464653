<template>
  <div class="editRole">
    <!-- 新增角色 -->
    <el-dialog
      title="新增角色"
      :visible.sync="dialogVisible"
      width="30%"
      :show-close="true"
      :close-on-click-modal="false"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
        size="small"
      >
        <el-form-item prop="name">
          <span>角色名称</span>
          <br />
          <el-input
            v-model.trim="ruleForm.name"
            placeholder="请输入角色名称"
            maxlength="10"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <span>这是一段信息</span> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="submitForm('ruleForm')"
          size="small"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addRoles } from '@/api/systemManagement/roleManagement/index.js'
export default {
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        name: '',
      },
      loading: false,
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          { max: 12, message: '长度在 12 个字符以内', trigger: 'change' }
        ]
      }
    };
  },
  methods: {
    openAdd() {
      this.ruleForm = {
        name: '',
      };
      this.dialogVisible = true;
    },
    // 点击提交
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const res = await addRoles({ roleName: this.ruleForm.name });
          console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功！',
              type: 'success'
            });
            this.loading = false;
            this.$emit('getSelectRole');
            this.dialogVisible = false;
          } else {
            this.$message({
              showClose: true,
              message: '新增失败！' + res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.editRole {
  ::v-deep.el-button--primary {
    margin-left: 30px;
  }
}
</style>