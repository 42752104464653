import request from '@/utils/request';

// 查看选手列表分页
export function getSelectPlayer(data) {
  return request({
    url: '/player-management/selectPlayer',
    method: 'post',
    data
  });
}

// 查看比赛列表下拉框
export function getSelectCompetitionDrop() {
  return request({
    url: '/competition-management/selectCompetitionDrop',
    method: 'get'
  });
}

// 新增选手
export function addPlayer(data) {
  return request({
    url: '/player-management/addPlayer',
    method: 'post',
    data
  });
}

// 查看选手详情
export function getSelectPlayerById(params) {
  return request({
    url: '/player-management/selectPlayerById',
    method: 'get',
    params
  });
}

// 编辑选手
export function updatePlayer(data) {
  return request({
    url: '/player-management/updatePlayer',
    method: 'post',
    data
  });
}

// 根据id批量删除选手
export function deletePlayer(data) {
  return request({
    url: '/player-management/deletePlayer',
    method: 'post',
    data
  });
}

// 查看所有比赛
export function getSelectAllCom() {
  return request({
    url: '/competition-management/selectAllCom',
    method: 'get'
  });
}

// 根据比赛id查看有哪些赛程
export function getSelectscheduleList(params) {
  return request({
    url: '/competition-schedule/selectscheduleList',
    method: 'get',
    params
  });
}

// 下载模板
export function download() {
  return request({
    url: '/file/download/template',
    method: 'get',
    responseType: 'blob',//将文件流转成blob对象
  });
}

// 查看参数选手
export function getContestPlayer(params) {
  return request({
    url: '/player-management/contest/player',
    method: 'get',
    params
  });
}

// 查询场地下拉框
export function getSelectSiteDown(params) {
  return request({
    url: '/site-management/selectSiteDown',
    method: 'get',
    params
  });
}
