<template>
  <div class="competitionProject">
    <!--比赛项目管理 -->
    <div v-if="this.$route.path === '/home/competitionProject'">
      <div class="searchBtn">
        <!-- <div class="inputContent"> -->
        <!-- <el-cascader
          ref="refSubCat"
          v-model="typeVal"
          :options="field105Options"
          :style="{ width: '180px' }"
          placeholder="请选择比赛类型"
          @change="handleChange"
          clearable
        ></el-cascader> -->
        <el-select
          v-model="paging.data.type"
          placeholder="请选择比赛类型"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <!-- </div> -->
        <!-- <div class="btn"> -->
        <el-button class="btnBox1" type="primary" size="small" @click="search"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="add">新增</el-button>
        <!-- </div> -->
      </div>
      <div class="tableContent">
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          style="width: 100%"
          size="small"
        >
          <!-- <el-table-column type="selection"> </el-table-column> -->
          <el-table-column label="比赛编号" prop="number" width="90">
          </el-table-column>
          <el-table-column prop="name" label="比赛名称（项目）">
          </el-table-column>
          <el-table-column
            prop="type"
            label="比赛类型"
            width="140"
          ></el-table-column>
          <el-table-column label="比赛场地">
            <template slot-scope="scope">
              <span
                v-for="(item, index) in scope.row.siteVos"
                :key="index"
                style="margin-right: 5px"
              >
                {{ item.siteCity }}-{{ item.siteName }}
                <span v-if="scope.row.siteVos.length - 1 !== index">、</span>
              </span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="shootCount"
            label="射击发数"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="ompetitionTime"
            label="比赛限时（分钟）"
            width="115"
            show-overflow-tooltip
          >
          </el-table-column> -->
          <el-table-column
            prop="startTime"
            :show-overflow-tooltip="true"
            label="开始时间"
            width="140"
          >
          </el-table-column>
          <el-table-column
            prop="endTime"
            label="结束时间"
            width="140"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <div v-if="scope.row.status === 1">
                <span class="ydBox ydBox1"></span>
                <span class="nameBox">未开始</span>
              </div>
              <div v-if="scope.row.status === 2">
                <span class="ydBox ydBox4"></span>
                <span class="nameBox nameBox4">已分组</span>
              </div>
              <div v-if="scope.row.status === 3">
                <span class="ydBox ydBox2"></span>
                <span class="nameBox nameBox2">进行中</span>
              </div>
              <div v-if="scope.row.status === 4">
                <span class="ydBox ydBox3"></span>
                <span class="nameBox nameBox3">已结束</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.id)"
                >编辑</el-button
              >
              <!-- <el-button
                v-else
                type="text"
                size="small"
                @click="edit(scope.row.id)"
                >查看</el-button
              > -->
              <el-button type="text" size="small" @click="delBtn(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="paging.page"
          :page-size="paging.size"
          layout="total, prev, pager, next, jumper"
          :total="paging.total"
        >
        </el-pagination>
      </div>
    </div>
    <div
      v-else-if="this.$route.path === '/home/competitionProject/addProjects'"
    >
      <AddProjects></AddProjects>
    </div>
    <div
      v-else-if="this.$route.path === '/home/competitionProject/editProject'"
    >
      <EditProjects></EditProjects>
    </div>
  </div>
</template>

<script>
import { getSelectCompetition, deleteCompetition } from '@/api/eventManagement/competitionProject/index.js'
import AddProjects from '@/components/eventManagement/competitionProject/addProjects.vue'
import EditProjects from '@/components/eventManagement/competitionProject/editProjects.vue'
export default {
  name: 'CompetitionProject',
  components: {
    AddProjects, EditProjects
  },
  created() {
    this.getSelectCompetition();
  },
  data() {
    return {
      // field105Options: [
      //   {
      //     "label": "男子",
      //     "value": 1,
      //     "id": 103,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 11,
      //       "id": 105,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 12,
      //       "id": 106,
      //     }]
      //   }, {
      //     "label": "女子",
      //     "value": 2,
      //     "id": 104,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 21,
      //       "id": 113,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 22,
      //       "id": 114,
      //     }]
      //   }, {
      //     "label": "不限",
      //     "value": 3,
      //     "id": 105,
      //     "children": [{
      //       "label": "10米气步枪",
      //       "value": 31,
      //       "id": 115,
      //     }, {
      //       "label": "10米气手枪",
      //       "value": 32,
      //       "id": 116,
      //     }]
      //   }
      // ], // 比赛类型：男子（女子）10米步枪（手枪）资格赛、决赛（夺冠赛

      // typeVal: [],
      // type: '',
      loading: true,
      paging: {
        page: 1,
        size: 13,
        data: {
          type: '',
        },
        total: 0
      },
      tableData: [], // 比赛列表
      options: [
        {
          id: 1,
          label: '男子10米气步枪'
        },
        {
          id: 2,
          label: '男子10米气手枪'
        },
        {
          id: 3,
          label: '女子10米气步枪'
        },
        {
          id: 4,
          label: '女子10米气手枪'
        },
        {
          id: 5,
          label: '不限10米气步枪'
        },
        {
          id: 6,
          label: '不限10米气手枪'
        },
      ]
    }
  },
  methods: {
    // 获取比赛列表
    async getSelectCompetition() {
      this.loading = true;
      // console.log(this.paging);
      const res = await getSelectCompetition(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        this.$forceUpdate();
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
      // console.log(this.tableData);
      this.tableData.forEach(item => {
        item.ompetitionTime = item.ompetitionTime / 60;
      })
    },
    // 点击搜索
    async search() {
      // console.log(this.type);
      // this.paging.data.type = this.type;
      this.paging.page = 1;
      this.getSelectCompetition();
    },
    // 级联选择器change事件
    // handleChange(value) {
    //   // console.log(value);
    //   this.field105Options.forEach(item => {
    //     if (value.length > 0) {
    //       if (value[0] === item.value) {
    //         let str1 = item.label
    //         if (item.children) {
    //           item.children.forEach(item1 => {
    //             if (value[1] === item1.value) {
    //               let str2 = item1.label
    //               this.type = str1 + str2;
    //             }
    //           })
    //         } else {
    //           this.type = str1;
    //         }
    //       }
    //     } else {
    //       this.type = ''
    //     }
    //   })
    // },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectCompetition()
    },
    // 新增
    add() {
      // console.log(111)
      sessionStorage.removeItem("competitionId");
      this.$router.push('/home/competitionProject/addProjects');
    },
    // 删除比赛
    delBtn(val) {
      this.$confirm('确认删除该比赛?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteCompetition({ competitionId: val });
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSelectCompetition();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 编辑
    edit(val) {
      // console.log(val);
      this.$router.push('/home/competitionProject/editProject');
      sessionStorage.setItem('competitionId', val);
    },
  }
}
</script>

<style lang="scss" scoped>
.competitionProject {
  padding: 20px;

  .searchBtn {
    ::v-deep.el-cascader {
      .el-input {
        width: 100%;
      }
    }
    // .btnBox1 {
    //   margin: 0 !important;
    // }
  }

  .tableContent {
    margin-top: 20px;

    // .el-table--border {
    //   border-bottom: 1px solid #ebeef5;
    // }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }
  .ydBox {
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-right: 5px;
  }
  .ydBox1 {
    background: #aaa;
  }
  .ydBox2 {
    background: #0e8ddf;
  }
  .ydBox3 {
    background: #00b050;
  }
  .ydBox4 {
    background: #f77120;
  }
  .nameBox {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
  }
  .nameBox2 {
    color: #0e8ddf;
  }
  .nameBox3 {
    color: #00b050;
  }
  .nameBox4 {
    color: #f77120;
  }
  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
