import request from '@/utils/request'

// 查看比赛列表分页
export function getSelectComList(data) {
  return request({
    url: '/score-query/selectComList',
    method: 'post',
    data
  });
}

// 根据赛程id查看所有选手
export function getSelectCompetitionPlayer(params) {
  return request({
    url: '/score-query/selectCompetitionPlayer',
    method: 'get',
    params
  });
}

// 根据选手id查询成绩详情
export function getSelectScoreByPlayerId(params) {
  return request({
    url: '/score-query/selectScoreByPlayerId',
    method: 'get',
    params
  });
}

// 编辑个人射击成绩
export function updateShootByPlayer(data) {
  return request({
    url: '/score-query/updateShootByPlayer',
    method: 'post',
    data
  });
}

// 根据选手新增射击分数
export function addShootScore(data) {
  return request({
    url: '/shooting-score/shooting/score',
    method: 'post',
    data
  });
}

// 根据比赛id查看已结束的赛程
export function getSelectscheduleList(params) {
  return request({
    url: '/score-query/selectscheduleList',
    method: 'get',
    params
  });
}