<template>
  <div class="playerInformation">
    <!-- 选手信息 -->
    <div v-if="this.$route.path === '/home/playerInformation'">
      <allPlayers></allPlayers>
      <contestant></contestant>
    </div>
    <div v-else-if="this.$route.path === '/home/playerInformation/addPlayer'">
      <AddPlayer></AddPlayer>
    </div>
    <div v-else>
      <EditPlayer></EditPlayer>
    </div>
  </div>
</template>

<script>
import AddPlayer from './addPlayer.vue'
import EditPlayer from '@/components/playerManagement/editPlayer.vue'
import allPlayers from './allPlayers.vue'
import contestant from './contestant.vue'
export default {
  name: 'PlayerInformation',
  components: {
    AddPlayer, EditPlayer, allPlayers, contestant
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.playerInformation {
  padding: 20px;
  .allPlayersBox {
    border: 1px solid #ccc;
    padding: 15px;
    box-sizing: border-box;
    .playerText {
      color: #666;
      margin-bottom: 15px;
    }
  }
  .contestant {
    margin-top: 30px;
  }

  .searchBtn {
    ::v-deep.el-cascader {
      .el-input {
        width: 100%;
      }
    }
    .btnBox1 {
      margin: 0 !important;
    }
  }

  ::v-deep .tableContent {
    margin-top: 20px;

    // .el-table--border {
    //   border-bottom: 1px solid #ebeef5;
    // }
    td.el-table_1_column_9 {
      .cell {
        padding: 0 !important;
      }
    }
    td.el-table_1_column_10 {
      .cell {
        padding: 0 !important;
      }
    }
    td.el-table_1_column_11 {
      .cell {
        padding: 0 !important;
      }
    }
    .litDelBox {
      display: flex;
      // justify-content: center;
      width: 100%;
      height: 32px;
      line-height: 32px;
      border: none;
      padding-left: 10px;
      text-align: left;
      border-top: 1px solid #e6ebff;
      // border-right: none;
      box-sizing: border-box;
      overflow: hidden;
      ::v-deep .el-select--mini {
        width: 100%;
        height: 100%;
        .el-input--mini {
          height: 100%;
          .el-input__inner {
            height: 60%;
            line-height: 100%;
            border: none;
          }
        }
      }
    }
    .litDelBox1 {
      border-top: none;
    }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>
