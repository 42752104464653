import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: '',//请求token

  },
  mutations: {
    changeToken: function (state, token) {
      state.token = token
    }
  },
  actions: {
    setToken({ commit, state }, token) {
      // console.log(token);
      commit("changeToken", token)
    }
  },
  modules: {
  }
})
