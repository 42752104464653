<template>
  <div class="deviceManagement">
    <!--设备管理 -->
    <div class="searchBtn">
      <el-select
        v-model="paging.data.type"
        placeholder="请选择场地状态"
        @change="search"
      >
        <el-option
          v-for="item in stateList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="small" @click="search">查询</el-button>
      <el-button type="primary" size="small" @click="add">新增</el-button>
    </div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        size="small"
      >
        <el-table-column type="index" label="序号" width="100">
        </el-table-column>
        <el-table-column label="设备类型">
          <template slot-scope="scope">
            <span v-if="scope.row.type === 1">气步枪</span>
            <span v-if="scope.row.type === 2">气手枪</span>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="设备编号"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="edit(scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteDevice(scope.row.id)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="paging.page"
        :page-size="paging.size"
        layout="total, prev, pager, next, jumper"
        :total="paging.total"
      >
      </el-pagination>
    </div>
    <AddDevice ref="addChild" @getSelectDevice="getSelectDevice"></AddDevice>
    <EditDevice ref="editChild" @getSelectDevice="getSelectDevice"></EditDevice>
  </div>
</template>
</template>

<script>
import { getSelectDevice, deleteDevice } from '@/api/deviceManagement/index.js'
import AddDevice from './addDevice.vue'
import EditDevice from '@/components/deviceManagement/editDevice.vue'
export default {
  name: 'DeviceManagement',
  components: {
    AddDevice, EditDevice
  },
  created() {
    this.getSelectDevice();
  },
  data() {
    return {
      paging: {
        data: {
          type: null
        },
        page: 1,
        size: 13,
        total: 0
      },
      loading: false,
      stateList: [
        {
          label: '全部',
          value: null
        },
        {
          label: '气步枪',
          value: 1
        },
        {
          label: '气手枪',
          value: 2
        },
      ],
      tableData: [], // 设备列表
    }
  },
  methods: {
    // 获取场地列表
    async getSelectDevice() {
      this.loading = true;
      const res = await getSelectDevice(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        // console.log(this.paging.total)
        this.loading = false;
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectDevice()
    },
    // 点击搜索
    async search() {
      this.paging.page = 1;
      this.getSelectDevice();
    },
    // 删除设备
    deleteDevice(val) {
      this.$confirm('确认删除该设备?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await deleteDevice({ deviceId: val });
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: 'success',
            message: '删除成功!'
          });
          this.getSelectDevice();
        } else {
          this.$message({
            showClose: true,
            type: 'error',
            message: '删除失败!' + res.msg
          });
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增
    add() {
      // console.log('111')
      this.$refs.addChild.openAdd();
    },
    // 编辑
    edit(val) {
      this.$refs.editChild.openEdit(val)
    },
  }
}
</script>

<style lang="scss" scoped>
.deviceManagement {
  padding: 20px;

  .litDelBox {
    display: flex;
    // justify-content: center;
    width: 100%;
    height: 32px;
    line-height: 32px;
    border: none;
    padding-left: 10px;
    text-align: left;
    border-top: 1px solid #e6ebff;
    // border-right: none;
    box-sizing: border-box;
    overflow: hidden;
    ::v-deep .el-select--mini {
      width: 100%;
      height: 100%;
      .el-input--mini {
        height: 100%;
        .el-input__inner {
          height: 60%;
          line-height: 100%;
          border: none;
        }
      }
    }
  }
  .litDelBox1 {
    border-top: none;
  }

  .tableContent {
    margin-top: 20px;

    // ::v-deep.el-table .el-table__body .el-table__cell {
    //     height: 32px !important;
    //     padding: 0px !important;
    //     line-height: 32px !important;
    // }

    // ::v-deep.el-table th.el-table__cell {
    //     height: 35px !important;
    //     padding: 0px !important;
    //     line-height: 35px !important;
    // }

    /* .el-table--border {
      border-bottom: 1px solid #ebeef5;
    } */
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>


