<template>
  <div class="detailsBox">
    <!-- 比赛详情 -->
    <div class="header">
      <div class="tablistbox">
        <a
          href="javascript:void(0);"
          :class="index == isactive ? 'active' : ''"
          @click="handelchange(index)"
          :key="index"
          v-for="(item, index) in tablist"
          >{{ item.name }}</a
        >
      </div>
      <router-view ref="child" :is="currentView" keep-alive></router-view>
      <!-- <qualificationDetails :is="currentView" keep-alive></qualificationDetails> -->
    </div>
  </div>
</template>

<script>
import { getSelectscheduleList } from '@/api/realTime/index.js'
import qualificationDetails from './details/qualificationDetails.vue';
import eliminate from './details/eliminate.vue';
import finalDetails from './details/finalDetails.vue';
import championshipDetails from './details/championshipDetails.vue';
import webSocket from '@/api/socket.js'
import urls from '@/utils/url.js'
export default {
  name: 'DetailsBox',
  components: {
    qualificationDetails,
    eliminate,
    finalDetails,
    championshipDetails
  },
  data() {
    return {
      isactive: this.$route.params.index ? this.$route.params.index : 0,
      tablist: [],
      currentView: this.$route.params.currentView ? this.$route.params.currentView : 'qualificationDetails',
      competitionId: parseInt(sessionStorage.getItem('competitionId')),
      lockReconnect: false,  //避免ws重复连接
      wsUrl: urls.websocketUrl + '/websocket/', // webSocket地址
    };
  },
  created() {
    this.getSelectscheduleList();

    // console.log('1', parseInt(sessionStorage.getItem('competitionId')));
    // webSocket.webSocketInit(process.env.VUE_APP_BASE_API.replace("http","ws")+"/evgis/todoStatus")
    webSocket.websocketInit(this.wsUrl + `"competitionId"` + ':' + `"` + parseInt(sessionStorage.getItem('competitionId')) + `"` + ',' + `"mark"` + ':' + `"backstage"`)	//初始化webSocket

    // 按需进行绑定回调函数
    webSocket.setOpenCallback(res => {
      console.log("连接建立成功111", res);
    })
    webSocket.setMessageCallback(res => {
      console.log("接收到回信1", res);
      this.$refs.child.getSelectShootingByGroupName();
    })
    webSocket.setErrorCallback(res => {
      this.reconnect();
      console.log("连接异常", res);
    })
    webSocket.setCloseCallback(res => {
      if (parseInt(sessionStorage.getItem('competitionId'))) {
        this.reconnect();
      }
      console.log("连接关闭2", res);
    })
  },
  //生命周期若已设置定时器，需清空定时器beforeDestroy()
  beforeDestroy() {
    // console.log('111');
    sessionStorage.setItem('competitionId', null);
    webSocket.close();
    // clearInterval(this.refreshData);
    // this.refreshData = null;
    // console.log(1);
    // webSocket.close()
    // console.log(2);
  },
  methods: {
    // 获取赛程阶段
    async getSelectscheduleList() {
      const res = await getSelectscheduleList({ competitionId: this.competitionId });
      // console.log(res);
      if (res.status === 200) {
        if (res.data) {
          // console.log(res.data);
          sessionStorage.setItem('scheduleId', res.data[0] ? res.data[0].scheduleId : null);
          // console.log('4');
          if (res.data[0] && res.data[0].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[0].scheduleId });
          } else if (res.data[0] && res.data[0].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[0].scheduleId });
          } else if (res.data[0] && res.data[0].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[0].scheduleId });
          }
          // else if (res.data[0] && res.data[0].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[0].scheduleId });
          // }
          if (res.data[1] && res.data[1].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[1].scheduleId });
          } else if (res.data[1] && res.data[1].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[1].scheduleId });
          } else if (res.data[1] && res.data[1].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[1].scheduleId });
          }
          // else if (res.data[1] && res.data[1].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[1].scheduleId });
          // }
          if (res.data[2] && res.data[2].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[2].scheduleId });
          } else if (res.data[2] && res.data[2].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[2].scheduleId });
          } else if (res.data[2] && res.data[2].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[2].scheduleId });
          }
          // else if (res.data[2] && res.data[2].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[2].scheduleId });
          // }
          if (res.data[3] && res.data[3].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[3].scheduleId });
          } else if (res.data[3] && res.data[3].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[3].scheduleId });
          } else if (res.data[3] && res.data[3].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[3].scheduleId });
          }
          // else if (res.data[3] && res.data[3].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[3].scheduleId });
          // }
          if (res.data[4] && res.data[4].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[4].scheduleId });
          } else if (res.data[4] && res.data[4].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[4].scheduleId });
          } else if (res.data[4] && res.data[4].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[4].scheduleId });
          }
          // else if (res.data[4] && res.data[4].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[4].scheduleId });
          // }
          if (res.data[5] && res.data[5].scheduleName === '1') {
            this.tablist.push({ name: '淘汰赛详情', scheduleId: res.data[5].scheduleId });
          } else if (res.data[5] && res.data[5].scheduleName === '2') {
            this.tablist.push({ name: '资格赛详情', scheduleId: res.data[5].scheduleId });
          } else if (res.data[5] && res.data[5].scheduleName === '3') {
            this.tablist.push({ name: '决赛详情', scheduleId: res.data[5].scheduleId });
          }
          // else if (res.data[5] && res.data[5].scheduleName === '6') {
          //   this.tablist.push({ name: '夺冠赛详情', scheduleId: res.data[5].scheduleId });
          // }
        }
        if (this.tablist.length > 0) {
          if (this.tablist[0] && this.tablist[0].name === '淘汰赛详情') {
            this.currentView = 'eliminate';
          } else if (this.tablist[0] && this.tablist[0].name === '资格赛详情') {
            this.currentView = 'qualificationDetails';
          } else if (this.tablist[0] && this.tablist[0].name === '决赛详情') {
            this.currentView = 'finalDetails';
          } else if (this.tablist[0] && this.tablist[0].name === '夺冠赛详情') {
            this.currentView = 'championshipDetails';
          }
        }
      }
    },
    reconnect() {
      let that = this;
      if (that.lockReconnect) return;
      that.lockReconnect = true;
      setTimeout(function () {     //没连接上会一直重连，设置延迟避免请求过多
        // console.log('11', that.wsUrl);
        webSocket.websocketInit(that.wsUrl + `"competitionId"` + ':' + `"` + parseInt(sessionStorage.getItem('competitionId')) + `"` + ',' + `"mark"` + ':' + `"backstage"`)	//初始化webSocket

        // 按需进行绑定回调函数
        webSocket.setOpenCallback(res => {
          console.log("连接建立成功", res);
        })
        webSocket.setMessageCallback(res => {
          console.log("接收到回信2", res);
          that.$refs.child.getSelectShootingByGroupName();
        })
        webSocket.setErrorCallback(res => {
          that.reconnect();
          console.log("连接异常", res);
        })
        webSocket.setCloseCallback(res => {
          if (parseInt(sessionStorage.getItem('competitionId'))) {
            that.reconnect();
          }
          console.log("连接关闭3", res);
        })
        that.lockReconnect = false;
      }, 2000);
    },
    // --------------------------
    handelchange(index) {
      this.isactive = index;
      if (index === 0) {
        if (this.tablist.length > 0) {
          if (this.tablist[0] && this.tablist[0].name === '淘汰赛详情') {
            this.currentView = 'eliminate';
          } else if (this.tablist[0] && this.tablist[0].name === '资格赛详情') {
            this.currentView = 'qualificationDetails';
          } else if (this.tablist[0] && this.tablist[0].name === '决赛详情') {
            this.currentView = 'finalDetails';
          } else if (this.tablist[0] && this.tablist[0].name === '夺冠赛详情') {
            this.currentView = 'championshipDetails';
          }
        }
        sessionStorage.setItem('scheduleId', this.tablist[0].scheduleId);
      } else if (index === 1) {
        if (this.tablist.length > 0) {
          if (this.tablist[1] && this.tablist[1].name === '淘汰赛详情') {
            this.currentView = 'eliminate';
          } else if (this.tablist[1] && this.tablist[1].name === '资格赛详情') {
            this.currentView = 'qualificationDetails';
          } else if (this.tablist[1] && this.tablist[1].name === '决赛详情') {
            this.currentView = 'finalDetails';
          } else if (this.tablist[1] && this.tablist[1].name === '夺冠赛详情') {
            this.currentView = 'championshipDetails';
          }
        }
        sessionStorage.setItem('scheduleId', this.tablist[1].scheduleId);
      } else if (index === 2) {
        if (this.tablist.length > 0) {
          if (this.tablist[2] && this.tablist[2].name === '淘汰赛详情') {
            this.currentView = 'eliminate';
          } else if (this.tablist[2] && this.tablist[2].name === '资格赛详情') {
            this.currentView = 'qualificationDetails';
          } else if (this.tablist[2] && this.tablist[2].name === '决赛详情') {
            this.currentView = 'finalDetails';
          } else if (this.tablist[2] && this.tablist[2].name === '夺冠赛详情') {
            this.currentView = 'championshipDetails';
          }
        }
        sessionStorage.setItem('scheduleId', this.tablist[2].scheduleId);
      } else if (index === 3) {
        if (this.tablist.length > 0) {
          if (this.tablist[3] && this.tablist[3].name === '淘汰赛详情') {
            this.currentView = 'eliminate';
          } else if (this.tablist[3] && this.tablist[3].name === '资格赛详情') {
            this.currentView = 'qualificationDetails';
          } else if (this.tablist[3] && this.tablist[3].name === '决赛详情') {
            this.currentView = 'finalDetails';
          } else if (this.tablist[3] && this.tablist[3].name === '夺冠赛详情') {
            this.currentView = 'championshipDetails';
          }
        }
        sessionStorage.setItem('scheduleId', this.tablist[3].scheduleId);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.detailsBox {
  margin: 0 20px;
  position: relative;
  margin-bottom: 20px;
  .tablistbox {
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
    a {
      color: #555;
      height: 55px;
      line-height: 55px;
      font-size: 14px;
      margin-right: 100px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
      text-align: center;
      text-decoration: none;
      display: inline-block;
    }
    .active {
      position: relative;
      height: 55px;
      line-height: 55px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #06206b;
      border-bottom: 3px solid #06206b;
    }
  }
}
</style>