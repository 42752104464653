<template>
  <!--实时赛况 -->
  <div class="realTime">
    <div v-if="this.$route.path === '/home/realTime'">
      <div class="searchBtn">
        <!-- <div class="inputContent"> -->
        <el-input
          placeholder="请输入比赛名称"
          v-model="paging.data.name"
          clearable
        >
        </el-input>
        <el-select
          v-model="paging.data.type"
          clearable
          placeholder="请选择比赛类型"
          @change="search"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          >
          </el-option>
        </el-select>
        <!-- </div> -->
        <!-- <div class="btn"> -->
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <!-- </div> -->
      </div>
      <div class="tableContent">
        <el-table :data="tableData" border style="width: 100%" size="small">
          <el-table-column prop="number" label="比赛编号" width="100">
          </el-table-column>
          <el-table-column prop="name" label="比赛名称（项目）">
          </el-table-column>
          <el-table-column prop="type" label="比赛类型" width="150">
          </el-table-column>
          <el-table-column prop="startTime" label="比赛时间" width="150">
          </el-table-column>
          <el-table-column label="状态" width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.status === 1">
                <span class="ydBox ydBox1"></span>
                <span class="nameBox">未开始</span>
              </div>
              <div v-if="scope.row.status === 2">
                <span class="ydBox ydBox4"></span>
                <span class="nameBox nameBox4">已分组</span>
              </div>
              <div v-if="scope.row.status === 3">
                <span class="ydBox ydBox2"></span>
                <span class="nameBox nameBox2">进行中</span>
              </div>
              <div v-if="scope.row.status === 4">
                <span class="ydBox ydBox3"></span>
                <span class="nameBox nameBox3">已结束</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="details(scope.row)"
                >查看</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="paging.p"
          :page-size="paging.ps"
          layout="total, prev, pager, next, jumper"
          :total="paging.total"
        >
        </el-pagination>
      </div>
    </div>
    <div v-else-if="this.$route.path === '/home/realTime/detail'">
      <Details ref="editChild"></Details>
    </div>
  </div>
</template>

<script>
import { getSelectCompetition } from '@/api/realTime/index.js'
import Details from './details.vue'
export default {
  name: 'RealTime',
  components: {
    Details
  },
  created() {
    this.getSelectCompetition();
  },
  data() {
    return {
      loading: true,
      paging: {
        page: 1,
        size: 13,
        data: {
          name: '',
          status: null, // 比赛状态
          type: '',
        },
        total: 0
      },
      tableData: [], // 比赛列表
      options: [
        {
          id: 1,
          label: '男子10米气步枪'
        },
        {
          id: 2,
          label: '男子10米气手枪'
        },
        {
          id: 3,
          label: '女子10米气步枪'
        },
        {
          id: 4,
          label: '女子10米气手枪'
        },
        {
          id: 5,
          label: '不限10米气步枪'
        },
        {
          id: 6,
          label: '不限10米气手枪'
        },
      ],
    }
  },
  methods: {
    // 获取比赛列表
    async getSelectCompetition() {
      this.loading = true;
      // console.log(this.paging);
      const res = await getSelectCompetition(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        this.$forceUpdate();
        // console.log(this.paging.total)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
      // console.log(this.tableData);
      // this.tableData.forEach(item => {
      //   item.ompetitionTime = item.ompetitionTime / 60;
      // })
    },
    // 点击搜索
    async search() {
      // console.log(this.type);
      // this.paging.data.type = this.type;
      this.paging.page = 1;
      this.getSelectCompetition();
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getSelectCompetition()
    },

    // ---------------------------------
    // 查看详情
    details(val) {
      // console.log(val);
      sessionStorage.setItem('competitionId', val.id);
      this.$router.push('/home/realTime/detail');
    }
  }
}
</script>

<style lang="scss" scoped>
.realTime {
  padding: 20px;

  // .search {
  //     display: flex;
  //     width: 100%;

  //     // .inputContent {

  //     .el-input,
  //     .el-select,
  //     .el-cascader {
  //         width: 150px;
  //         margin-right: 30px;
  //     }

  //     // }

  //     // .btn {
  //     .el-button {
  //         margin-left: 40px;
  //         display: inline-block;
  //     }

  //     // }

  // }

  .tableContent {
    margin-top: 20px;

    // ::v-deep.el-table .el-table__body .el-table__cell {
    //     height: 32px !important;
    //     padding: 0px !important;
    //     line-height: 32px !important;
    // }

    // ::v-deep.el-table th.el-table__cell {
    //     height: 35px !important;
    //     padding: 0px !important;
    //     line-height: 35px !important;
    // }

    // .el-table--border {
    //   border-bottom: 1px solid #ebeef5;
    // }
    ::v-deep.el-table__body {
      .el-table__cell {
        padding: 0 !important;
      }
    }
    .ydBox {
      display: inline-block;
      width: 14px;
      height: 14px;
      border-radius: 7px;
      margin-right: 5px;
    }
    .ydBox1 {
      background: #aaa;
    }
    .ydBox2 {
      background: #0e8ddf;
    }
    .ydBox3 {
      background: #00b050;
    }
    .ydBox4 {
      background: #f77120;
    }
    .nameBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .nameBox2 {
      color: #0e8ddf;
    }
    .nameBox3 {
      color: #00b050;
    }
    .nameBox4 {
      color: #f77120;
    }
  }

  .block {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
</style>



