/**
 * 参数说明：
 *  webSocketURL：String    webSocket服务地址    eg: ws://127.0.0.1:8088/websocket (后端接口若为restful风格可以带参数)  
 *  callback：为带一个参数的回调函数
 *  message：String 要传递的参数值（不是一个必要的参数）
 */
export default {
  // 初始化websocket
  websocketInit(websocketUrl) {
    this.webSocket = new WebSocket(websocketUrl)
    this.webSocket.onopen = this.onOpenwellback;
    this.webSocket.onmessage = this.onMessageCallback;
    this.webSocket.onerror = this.onErrorCallback;
    this.webSocket.onclose = this.onCloseCallback;
  },
  // 自定义回调函数
  setOpenCallback(callback) {//与服务器建立连接成功的回调函数
    this.webSocket.onopen = callback
  },
  setErrorCallback(callback) {//与服务器建立连接失败的回调函数
    this.webSocket.onerroe = callback
  },
  setMessageCallback(callback) {//与服务器接收信息的回调函数
    this.webSocket.onmessage = callback
  },
  setCloseCallback(callback) {//与服务器连接断开的回调函数
    this.webSocket.onclose = callback
  },
  // 关闭连接
  close() {
    this.webSocket.close()
  },
  // 发送消息
  sendMessage(message) {
    this.webSocket.send(message)
  }
}