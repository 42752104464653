<template>
  <div class="allPlayersBox contestant">
    <div class="playerText">参赛选手</div>
    <div class="searchBtn">
      <el-input placeholder="请输入姓名" v-model="paging.playerName" clearable>
      </el-input>
      <el-select
        v-model="paging.competitionId"
        clearable
        placeholder="请选择比赛项目"
        @change="comChange"
        @clear="comClearBtn"
      >
        <el-option
          v-for="item in options1"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        clearable
        v-model="paging.siteId"
        placeholder="请选择比赛场地"
        no-data-text="请先选择比赛项目"
        @change="search"
        @clear="clearBtn"
      >
        <el-option
          v-for="item in options2"
          :key="item.id"
          :label="item.city + '—' + item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
    </div>
    <div class="tableContent">
      <el-table
        v-loading="loading"
        :data="tableData"
        border
        style="width: 100%"
        size="small"
      >
        <el-table-column label="选手编号" prop="playerNumber" width="120">
        </el-table-column>
        <el-table-column prop="playerName" label="选手姓名" width="100">
        </el-table-column>
        <el-table-column label="比赛名称" prop="competitionName">
        </el-table-column>
        <el-table-column prop="siteName" label="场地">
          <template slot-scope="scope">
            <span>{{ scope.row.siteCity }} — {{ scope.row.siteName }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="项目名称" prop="competitionName" width="120">
        </el-table-column> -->
        <el-table-column
          prop="competitionStartTime"
          label="比赛时间"
          width="180"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="paging.page"
        :page-size="paging.size"
        layout="total, prev, pager, next, jumper"
        :total="paging.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getContestPlayer, getSelectAllCom, getSelectSiteDown } from '@/api/playerManagement/index.js'
export default {
  name: 'contestant',
  props: {},
  data() {
    return {
      paging: {
        page: 1,
        size: 10,
        competitionId: null, // 比赛名称
        playerName: '',
        siteId: null,
        // level: 0,
        total: 0
      },
      competitionId: null,
      loading: true,
      tableData: [], // 选手列表
      deleteplayerIdList: [], // 删除选手id列表
      options1: [],
      options2: [],
    }
  },
  created() {
    this.getContestPlayer();
    this.getSelectAllCom();
    // this.getSelectSiteDown();
  },
  methods: {
    // 排序 {column,prop,order} 列数据|排序字段|排序方式
    // sortChange(column) {
    //   console.log(column);
    //   this.paging.level = null;
    //   if (column.order === null) {
    //     this.paging.level = null;
    //   }
    //   if (column.prop === 'competitionStartTime' && column.order === 'ascending') {
    //     this.paging.level = 0;
    //   }
    //   if (column.prop === 'competitionStartTime' && column.order === 'descending') {
    //     this.paging.level = 1;
    //   }
    //   this.getContestPlayer();
    // },

    // 比赛change事件
    comChange(params) {
      // console.log(params);
      this.search();
      this.getSelectSiteDown();
    },
    comClearBtn() {
      this.paging.competitionId = null;
      this.paging.siteId = null;
      this.search();
    },

    // 查询场地下拉框
    async getSelectSiteDown() {
      const res = await getSelectSiteDown({ competitionId: this.paging.competitionId });
      console.log(res);
      if (res.status === 200) {
        this.options2 = res.data;
      }
    },
    clearBtn() {
      this.paging.siteId = null;
    },

    // 获取所有比赛
    async getSelectAllCom() {
      const res = await getSelectAllCom();
      // console.log(res);
      if (res.status === 200) {
        this.options1 = res.data;
      }
    },
    // 获取用户列表
    async getContestPlayer() {
      this.loading = true;
      const res = await getContestPlayer(this.paging);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.paging.total = res.data.total;
        // console.log(this.tableData)
        this.loading = false;
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.msg
        });
      }
    },
    // 切换分页
    handleCurrentChange(val) {
      this.paging.page = val;
      this.getContestPlayer()
    },
    // 点击搜索
    async search() {
      // this.paging.data.competitionType = this.type;
      this.paging.page = 1;
      // console.log(this.paging);
      this.getContestPlayer();
    },
    // 新增
    add() {
      // console.log(111)
      this.$router.push('/home/playerInformation/addPlayer');
    },
    // 编辑
    edit(val) {
      // console.log(val);
      this.$router.push('/home/playerInformation/editPlayer');
      sessionStorage.setItem('playerId', val);
    },
  }
}
</script>

<style lang="scss" scoped>
.allPlayersBox {
  border: 1px solid #ccc;
  padding: 15px;
  box-sizing: border-box;
  .playerText {
    color: #666;
    margin-bottom: 15px;
  }
}
.contestant {
  margin-top: 30px;
}

.searchBtn {
  ::v-deep.el-cascader {
    .el-input {
      width: 100%;
    }
  }
  .btnBox1 {
    margin: 0 !important;
  }
}

::v-deep .tableContent {
  margin-top: 20px;

  // .el-table--border {
  //   border-bottom: 1px solid #ebeef5;
  // }
  td.el-table_1_column_9 {
    .cell {
      padding: 0 !important;
    }
  }
  td.el-table_1_column_10 {
    .cell {
      padding: 0 !important;
    }
  }
  td.el-table_1_column_11 {
    .cell {
      padding: 0 !important;
    }
  }
  ::v-deep.el-table__body {
    .el-table__cell {
      padding: 0 !important;
    }
  }
}

.block {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
</style>
